import React, { FunctionComponent, useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import "./styles.css";
import { parameters } from "../AnimationImageOverlay";

type props = {
  items?: parameters[];
  parametersValue?: parameters["param"];
  onClickItemIndex?: (parameters: any) => void;
  onClickActivateList?: (items: string[]) => void;
  uniqueValue?: boolean;
  itemsIndexActive?: number[];
};

const ChipList: FunctionComponent<props> = ({
  items,
  onClickItemIndex,
  onClickActivateList,
  uniqueValue = false,
  itemsIndexActive,
  parametersValue,
}: props) => {
  const [activesIndexes, setActivesIndexes] = useState<Record<number, boolean>>(
    {}
  );
  const [activeList, setActiveList] = useState<string[]>([]);

  useEffect(() => {
    if (itemsIndexActive && itemsIndexActive.keys.length == 0)
      itemsIndexActive.forEach((v) => {
        setActivesIndexes({ ...activesIndexes, [v]: true });
      });
  }, [activesIndexes, itemsIndexActive]);

  return (
    <div className="chip-list">
      {items?.map((v, i) => (
        <Chip
          key={i}
          variant={parametersValue === v.param ? undefined : "outlined"}
          //variant={ activesIndexes[i] ? undefined : 'outlined' }
          color="default"
          size="small"
          label={v.label}
          clickable
          onClick={() => {
            setActivesIndexes({ ...activesIndexes, [i]: !activesIndexes[i] });
            const list = activeList;
            const activeIndex: number = list.indexOf(v.label);
            if (uniqueValue) {
              setActivesIndexes({ [i]: !activesIndexes[i] });
              setActiveList([v.label]);
            } else {
              if (activeIndex > -1) {
                list.splice(activeIndex, 1);
              } else {
                list.push(v.label);
              }
            }

            if (onClickItemIndex) onClickItemIndex(v.param);
            if (onClickActivateList) onClickActivateList(activeList);
          }}
        />
      ))}
    </div>
  );
};

export default ChipList;
