import React, { useEffect, useRef, useState } from "react";
import { LayerGroup } from "react-leaflet";
import WmsTiles from "../../components/Leaflet/WMSTiles";
import MenuLayer from "../../components/Leaflet/MenuLayer";
import produtosRepositories, {
  IWms,
  WmsInitial,
} from "../../repositories/Produtos";
import { IPaginator } from "../../repositories/models";

const ICATiles = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<IWms[]>(WmsInitial);

  useEffect(() => {
    subscribed.current = true;
    getDados();
    return () => {
      subscribed.current = false;
    };
  }, []);

  async function getDados() {
    try {
      const { data } = await produtosRepositories.wms<IPaginator<IWms>>();

      if (subscribed.current) {
        setData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {data.map(({ menu, layers }, index) => (
        <MenuLayer name={menu} key={index}>
          <LayerGroup>
            {layers.map(({ layer, url }, index) => (
              <WmsTiles layers={layer} key={index} url={url} />
            ))}
          </LayerGroup>
        </MenuLayer>
      ))}
    </>
  );
};

export default ICATiles;
