import { IconButton, Slider } from "@material-ui/core";
import { Pause, PlayArrow } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";
import ChipList from "./ChipList";
import "./styles.css";
import { parameters } from "../AnimationImageOverlay";

type props = {
  onVelocityChange?: (velocity: number) => void;
  timeValue: number | number[];
  maxTimeValue?: number;
  timeLabel?: any;
  step: number;
  min: number;
  handlePlayPause: (isPlaying: boolean) => void;
  onTimelineChange: any;
};

const TimelineControl: FunctionComponent<props> = ({
  onVelocityChange,
  maxTimeValue = 10,
  timeValue,
  timeLabel,
  handlePlayPause,
  onTimelineChange,
  step,
  min,
}: props) => {
  const velocities: parameters[] = [
    {
      label: "1x",
      param: 500,
    },
    {
      label: "2x",
      param: 400,
    },
    {
      label: "3x",
      param: 100,
    },
  ];

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [parametersVelocidade, setParametersVelocidade] = useState<
    parameters["param"]
  >(velocities[0].param);

  function changeVelocidade(e: any) {
    if (onVelocityChange) {
      onVelocityChange(e);
    }
    setParametersVelocidade(e);
  }
  return (
    <div className="timeline-control">
      <div className="player">
        <IconButton
          size="small"
          color="default"
          onClick={() => {
            setIsPlaying(!isPlaying);
            handlePlayPause(!isPlaying);
          }}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <Slider
          value={timeValue}
          onChange={(event, number) => onTimelineChange(event, number)}
          disabled={isPlaying}
          step={step}
          min={min}
          max={maxTimeValue}
        />

        {timeLabel && <div className="label">{timeLabel}</div>}
      </div>
      <div className="velocity-control">
        <ChipList
          items={velocities}
          parametersValue={parametersVelocidade}
          uniqueValue={true}
          onClickItemIndex={changeVelocidade}
        />
      </div>
    </div>
  );
};

export default TimelineControl;
