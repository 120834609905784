import React, { ReactNode, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  Box,
  Grid,
  IconButton,
  Mark,
  Slider,
  Tooltip,
} from "@material-ui/core";
import { setGridHour } from "../../../store/Aerodromo/actionCreatores";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import Typography from "@material-ui/core/Typography";
import { isBrowser } from "react-device-detect";
type Anchor = "top" | "left" | "bottom" | "right";
type Icon = "down" | "top";

interface ISidebar {
  anchor: Anchor;
  open: boolean;
  icon: Icon;
  onClose: () => void;
  children: ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 400,
    padding: "0px",
  },
  drawerHeader: {
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center",
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    padding: "0px",
    margin: "0px",
  },
  paper: {
    padding: "0px",
    background: "transparent",
  },
}));

const MySliderShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const MySlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 10,
    width: 10,
    backgroundColor: "#0040ff",
    boxShadow: MySliderShadow,
    marginTop: -4,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: MySliderShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: -10,
    top: -15,
    "& *": {
      background: "transparent",
      color: "#a6a6a6",
      // color: '#a6a6a6',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

const Sidebar: React.FC<ISidebar> = (props: ISidebar) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme);
  const aerodromo = useSelector((state: RootState) => state.aerodromo);
  const [value, setValue] = React.useState([-24, 6]);

  const [marks, setMark] = React.useState<boolean | Mark[]>([]);

  useEffect(() => {
    setMark([
      {
        value: -96,
      },
      {
        value: 0,
      },
      {
        value: aerodromo.validades.horas_futuro_max,
      },
    ]);
  }, [aerodromo]);

  const onClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClose && props.onClose();
  };

  const handleSliderChange = (event: any, newValue: number | any) => {
    if (newValue[0] > -6) {
      newValue[0] = -6;
    }

    if (newValue[1] < 6) {
      newValue[1] = 6;
    }
    setValue(newValue);
  };

  const toggleDrawerDown = useSelector((state: RootState) =>
    state.menu == "close" ? true : false
  );

  const commit = (event: any, newValue: any | number[]) => {
    if (newValue[0] > -6) {
      newValue[0] = -6;
    }

    if (newValue[1] < 6) {
      newValue[1] = 6;
    }

    setValue(newValue);
    dispatch(setGridHour(newValue));
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#d9d9d9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={props.anchor}
          open={toggleDrawerDown}
          variant="persistent"
          //  classes={{
          //     paper: classes.paper
          // }}
        >
          <Box
            className={classes.drawerHeader}
            style={{ background: theme.bgColor, color: theme.fontColor }}
          >
            {isBrowser && (
              <Box
                border={0}
                style={{
                  position: "absolute",
                  width: "400px",
                  float: "right",
                  top: "0px",
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={8} spacing={0}>
                    <Grid
                      item
                      style={{
                        paddingTop: "2px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        textAlign: "center",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Horas de Gráfico:
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={2}
                        style={{
                          paddingTop: "10px",
                          marginRight: "5px",
                          textAlign: "right",
                          fontSize: "10px",
                        }}
                      >
                        Passado
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{ paddingTop: "2px", margin: "0px" }}
                      >
                        <MySlider
                          value={value}
                          onChange={handleSliderChange}
                          aria-labelledby="discrete-slider-small-steps"
                          onChangeCommitted={commit}
                          step={6}
                          valueLabelDisplay="on"
                          min={-96}
                          max={aerodromo.validades.horas_futuro_max}
                          marks={marks}
                          track="inverted"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          paddingTop: "10px",
                          margin: "0px",
                          paddingLeft: "5px",
                          textAlign: "left",
                          fontSize: "10px",
                        }}
                      >
                        Futuro
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} spacing={0}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={6}
                        spacing={0}
                        style={{
                          marginTop: "-10px",
                          padding: "0px",
                          textAlign: "right",
                        }}
                      >
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Informações de dados futuro
                              </Typography>
                              {
                                "Os dados futuros apresentados no gráfico provém de"
                              }{" "}
                              <em>
                                <b>{"modelagem numérica"}</b>
                              </em>{" "}
                              {"executada a partir do modelo"} <b>{"WRF"}</b>
                            </React.Fragment>
                          }
                        >
                          <IconButton style={{ color: theme.fontColor }}>
                            <InfoTwoToneIcon />
                          </IconButton>
                        </HtmlTooltip>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        spacing={0}
                        style={{
                          marginTop: "-10px",
                          padding: "0px",
                          textAlign: "right",
                        }}
                      >
                        <Tooltip title="Fechar Informações">
                          <IconButton
                            style={{ color: theme.fontColor }}
                            onClick={(e) => onClose(e)}
                          >
                            {props.icon === "down" ? (
                              <HighlightOffIcon />
                            ) : (
                              <ChevronLeftIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {props.children}
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default Sidebar;
