import React, { useEffect } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Dialog = withStyles(() => ({
  paperScrollPaper: {
    maxHeight: "calc(100% - 190px)",
  },
}))(MuiDialog);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IAppContentDialogProps extends React.PropsWithChildren<any> {
  showDialog: boolean;
  title?: string;
  saveButtonLabel?: string;
  onClose: (value: boolean) => void;
}

export default function AppContentDialog({
  showDialog = false,
  title,
  saveButtonLabel,
  children,
  onClose,
}: IAppContentDialogProps) {
  const [open, setOpen] = React.useState(showDialog);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  useEffect(() => {
    setOpen(showDialog);
  }, [setOpen, showDialog]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
    >
      {title && (
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {saveButtonLabel && (
          <Button autoFocus onClick={handleClose} color="primary">
            {saveButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
