import React, { FunctionComponent, ReactNode, useRef } from "react";
import "./styles.css";
import { ReactComponent as ChevronUp } from "../../../assets/svg/icons/icon-chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../assets/svg/icons/icon-chevron-down.svg";

type props = {
  children: ReactNode;
  title: string;
  isOpen: boolean;
  icon?: ReactNode;
  onTitleClick: (value: boolean) => void;
};

const SidebarCollapsible: FunctionComponent<props> = ({
  children,
  icon,
  isOpen = false,
  title,
  onTitleClick,
}: props) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={
        isOpen
          ? "redemet-mainsidebar-collapsible is-open"
          : "redemet-mainsidebar-collapsible"
      }
    >
      <div
        className="collapsible-titlebar"
        onClick={() => {
          // if(!isOpen) {
          //   contentWrapperRef.current?.style.setProperty('max-height', `${contentWrapperRef.current?.scrollHeight}px`);
          // } else {
          //   contentWrapperRef.current?.style.setProperty('max-height', `0`);
          // }
          onTitleClick(!isOpen);
        }}
      >
        {icon && <span className="icon-start">{icon}</span>}

        {title && <span className="title">{title}</span>}

        <span className="icon-end">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>
      <div className="collapsible-content-wrapper" ref={contentWrapperRef}>
        <div className="collapsible-content">{children}</div>
      </div>
    </div>
  );
};

export default SidebarCollapsible;
