import React, { FunctionComponent } from "react";
import "./styles.css";

type props = {
  title: string;
  endTitle?: string;
  topLabels?: string[];
  valuesList: { label: string; color: string[]; scale: number }[];
  valuesListTop?: { value: string; label?: string; scale: number }[];
};

const GradientLegend: FunctionComponent<props> = ({
  title,
  valuesList,
  valuesListTop,
  endTitle,
  topLabels,
}: props) => {
  function makeGradient(): string {
    let gradient = "linear-gradient(to right, ";
    let stops = 0;
    valuesList.forEach((item) => (stops += item.scale));
    const fraction = Math.round(100 / stops);
    //const progress = 0;

    for (let i = 0; i < valuesList.length; i++) {
      const v = valuesList[i];
      if (v.scale > 1) {
        const colorProgressFraction = Math.round(
          (fraction * v.scale) / v.color.length
        );
        let colorProgressProgress = 0;
        v.color.forEach((c) => {
          gradient += `${c} ${colorProgressProgress}%, `;

          colorProgressProgress += colorProgressFraction;
        });
      } else {
        v.color.forEach((c, j) => {
          gradient += `${c}`;
          if (j < v.color.length && i < valuesList.length) {
            if (j !== v.color.length - 1) {
              gradient += ", ";
            }
          }
        });
        if (i < valuesList.length - 1) {
          gradient += ", ";
        }
      }

      //progress += fraction;
    }

    gradient += ")";
    return gradient;
  }

  return (
    <div className="gradient-legend">
      <div className="gradient-legend-title">{title}</div>
      {topLabels && (
        <div className="gradient-top-labels">
          {topLabels.map((l, i) => (
            <span key={i} className="top-label">
              {l}
            </span>
          ))}
        </div>
      )}
      <div className="legend-values top">
        {valuesListTop &&
          valuesListTop.map((v, k) => (
            <LegendItem key={k} label={v.value} scale={v.scale} />
          ))}
      </div>
      <div
        className="gradient-legend-values"
        style={{ background: makeGradient() }}
      ></div>
      <div className="legend-values">
        {valuesList.map((v, k) => (
          <LegendItem key={k} label={v.label} scale={v.scale} />
        ))}
      </div>
      {endTitle && <div className="gradient-legend-title">{endTitle}</div>}
    </div>
  );
};

const LegendItem = ({ scale, label }: { scale: number; label: string }) => {
  let style;
  if (scale > 1) {
    style = { flex: `${scale}` };
  }
  return (
    <span className="value" style={style}>
      {label}
    </span>
  );
};

export default GradientLegend;
