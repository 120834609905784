import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import "./styles.css";
import { useDispatch } from "react-redux";
import { updateLayer } from "../../store/Layers/actionCreatores";

type Props = {
  contador: string | undefined;
  data: string | undefined;
  layer: string;
};

const AtualizacaoItem: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();

  return (
    <Grid container spacing={1} className="updated-at">
      <Grid item xs={2} className="atualizar">
        <IconButton
          size="small"
          onClick={() => dispatch(updateLayer(props.layer, true))}
        >
          <Refresh />
        </IconButton>
      </Grid>
      <Grid item xs={5} className="proxima-atualizacao">
        <h5>Próxima Atualizacao</h5>
        {props.contador}
      </Grid>
      <Grid item xs={5} className="atualizado">
        <h5>Atualizado em</h5>
        {props.data}
      </Grid>
    </Grid>
  );
};

export default AtualizacaoItem;
