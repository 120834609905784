import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  createStyles({
    root: {
      width: "100%",
      fontSize: "14px",
      fontWeight: "bold",
      margin: "0px",
      color: "#000",
      textDecoration: "none",
    },
  })
);

export default function Links() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogContent>
        <Container maxWidth="lg">
          <p>
            • <b>Brasil:</b>
          </p>
          <a
            className={classes.root}
            href={"https://bndmet.inmet.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » BNDMET"}
          </a>
          <a
            className={classes.root}
            href={"http://www.icea.gov.br/climatologia"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » Climatologia/ICEA - Instituto de Controle do Espaço Aéreo "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.inmet.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » INMET - Instituto Nacional de Meteorologia - Brasília/DF "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cptec.inpe.br"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » CPTEC/INPE - Centro de Previsão de Tempo e Estudos Climáticos "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.crn2.inpe.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » CRN - Centro Regional de Natal"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ana.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » ANA - Agência Nacional de Águas"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.itep.br/meteorologia/index.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » ITEP/LAMEPE - Instituto Tecnológico de Pernambuco - Laboratório de Meteorologia de Pernambuco - PE "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.dhn.mar.mil.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » CHM - Centro de Hidrografia da Marinha "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cpa.unicamp.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » Cepagri/UNICAMP - Centro de Pesquisas Meteorológicas e Climáticas Aplicadas a Agricultura "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.agritempo.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » Agritempo - Sistema de Monitoramento Agrometeorológico "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.unemet.al.org.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » UNEMET - União Nacional dos Estudiosos em Meteorologia "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.sbagro.org.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » SBAGRO - Sociedade Brasileira de Agrometeorologia "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.tempo.al.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » DHM -AL - Diretoria de Hidrometeorologia de Alagoas - AL"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.fepagro.rs.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » FEPAGRO - Fundação Estadual de Pesquisa Agropecuária - RS "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.funceme.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » FUNCEME - Fundação Cearense de Meteorologia e Recursos Hídricos - Fortaleza/CE "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.lpm.meteoro.ufrj.br"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » LPM - Laboratório de Prognósticos em Mesoescala - Depto. de Meteorologia - Instituto de Geociências"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.nemrh.uema.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » NEMRH-MA - Núcleo Estadual de Meteorologia e Recursos Hídricos do Maranhão - MA "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.sbmet.org.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » SBMET - Sociedade Brasileira de Meteorologia"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://simego.sectec.go.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » SIMEGO - Sistema de Meteorologia e Recursos Hídricos do Estado de Goiás - Goiânia/GO"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.simepar.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » SIMEPAR - Sistema Meteorológico do Paraná - Curitiba/PR"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.srh.ba.gov.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » SRH-BA - Superintendência de Recursos Hídricos da Bahia - Salvador/BA"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://orion.cpa.unicamp.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » CEPAGRI-UNICAMP Meteorologia - Universidade Estadual de Campinas - Campinas/SP"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ccen.ufal.br/met/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » UFAL - Universidade Federal de Alagoas - Departamento de Meteorologia"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meteorologia.ufrj.br"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » UFRJ - Universidade Federal do Rio de Janeiro - Departamento de Meteorologia"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ufpa.br/cg/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » UFPA - Universidade Federal do Pará - Centro de Geociências"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ufpel.tche.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » UFPel - Universidade Federal de Pelotas - Pelotas/RS"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ipmet.unesp.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » UNESP/IPMET - Universidade Estadual Paulista - Instituto de Pesquisas Meteorológicas - Bauru/SP"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.iag.usp.br"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » USP/IAG - Universidade de São Paulo - Instituto Astronômico e Geofísico - São Paulo/SP"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.labmet.univap.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » UNIVAP - Universidade Do vale do Paraíba - Laboratório de Meteorologia - São José dos Campos/SP"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cefet-rj.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » CEFET/RJ - Centro Federal de Educação Tecnológica Celso Suckow da Fonseca - Maracanã/RJ"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.climatempo.com.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Climatempo"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.tempoagora.com.br/index.php"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Tempo Agora"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.infotempo.com.br/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Infotempo"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.apolo11.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Apolo 11"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.canaldotempo.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » The Weather Channel - Brasil "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.wunderground.com/global/BZ.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » The Weather Underground - Brasil "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.metsul.com"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » MetSul Meteorologia "}
          </a>
          <br />
          <p>
            • <b>Mundiais:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.wmo.ch/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » OMM - Organização Meteorológica Mundial "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://aviationweather.gov/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » AWC - Aviation Weather Center "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.noaa.gov"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » NOAA - National Oceanic and Atmospheric Administration"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.lima.icao.int"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » OACI - Organização de Aviação Civil Internacional"}
          </a>
          <br />
          <p>
            • <b>Canadá:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.ns.ec.gc.ca/index.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » Green Lane - Serviço de Informação da Região do Atlântico "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://weather.ec.gc.ca/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » The Latest Weather Information for Canadians"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cmc.ec.gc.ca/indexe.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » CMC (Canadian Meteorological Center)"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ec.gc.ca/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Environment Canada Headquarters (Ottawa, Ontario)"}
          </a>
          <br />
          <p>
            • <b>Estados Unidos:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.cpc.ncep.noaa.gov/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » NCEP (National Centers for Environmental Prediction - Climate Prediction Center) (NMC)"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://grads.iges.org/colablurb.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » COLA (Center for Ocean-Land-Atmosphere Studies) "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ncar.ucar.edu/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » NCAR (National Center for Atmospheric Research)"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://iri.ldeo.columbia.edu/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » IRI (International Research Institute) for climate prediction NASA"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.weather.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » The Weather Channel"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://cnn.com/WEATHER/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » CNN - Weather Main Page"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.usatoday.com/weather/wfront.htm"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » USA Today - Weather"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.intellicast.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Intellicast World Weather"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://paoc.mit.edu/paoc/default.htm"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » PAOC - Program in Atmospheres, Oceans and Climate, MIT "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.wunderground.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » The Weather Underground"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ssec.wisc.edu/data/index.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » SSEC - Real-Time screen - GIF (resolution globe and CONUS with map overlays) "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cira.colostate.edu/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » CIRA - Real-Time screen - JPEG (resolution globe and CONUS with map overlays) "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://cirrus.sprl.umich.edu/wxnet"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » UM Weather - University of Michigan "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meto.umd.edu/%7Eowen/EARTHCAST/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » EARTHCAST - University of Maryland, Dept. of Meteorology "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://ww2010.atmos.uiuc.edu/%28gh%29/home.rxml"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » The University of Illinois - WW2010 Project"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.ocs.orst.edu/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Oregon State University Climate Services"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.hawaii.edu/News/world.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » University of Hawaii - World Weather "}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://weather.unisys.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Unisys Weather"}
          </a>
          <br />
          <a
            className={classes.root}
            href={
              "http://galaxy.einet.net/galaxy/Science/Geosciences/Meteorology-and-Climatology.html"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » Galaxy/Meteorology and Climatology Conjuntos de Dados Meteorológicos "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://nowlin.css.msu.edu/data/solar.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {" » University of Michigan "}
          </a>
          <br />
          <p>
            • <b>Costa Rica:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.imn.ac.cr/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Instituto Meteorológico Nacional -- Ministerio de Recursos Naturales, Energía y Minas"
            }
          </a>
          <br />
          <p>
            • <b>Argentina:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.infoclima.com/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Córdoba Weather Center - previsão de tempo por 5 dias para a Argentina"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meteofa.mil.ar/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Servicio Meteorológico Nacional - Fuerza Aerea Argentina"}
          </a>
          <br />
          <p>
            • <b>Chile:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.meteochile.cl/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Dirección Meteorológica de Chile"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://met.dgf.uchile.cl/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Universidad de Chile - Sección de Meteorología, Departamento de Geofísica"
            }
          </a>
          <br />
          <p>
            • <b>Colombia:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.ideam.gov.co/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Instituto de Hidrologia, Meteorologia y Estodios Ambientales"
            }
          </a>
          <br />
          <p>
            • <b>Peru:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.met.igp.gob.pe/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » CPNTC-IGP - Centro de Predicción Numérica del Tiempo y Clima - Instituto Geofísico del Peru"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.senamhi.gob.pe"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Senamhi - Servicio Nacional de Meteorologia e Hidrologia del Peru"
            }
          </a>
          <br />
          <p>
            • <b>Uruguai:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.meteorologia.com.uy/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Dirección Nacional de Meteorología"}
          </a>
          <br />
          <p>
            • <b>Europa:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.ecmwf.int/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » ECMWF - European Certer for Medium-Range Weather Forecasts"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.knmi.nl/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » KNMI - Holanda"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meteo.fr/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Météo France"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.fmi.fi/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » FMI - Finlândia"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meto.gov.uk/home.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » UKMO - Reino Unido"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.univie.ac.at/ZAMG/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » ZAMG - Áustria"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.dwd.de/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » DWD - Alemanha"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.smhi.se/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » SMHI - Suécia"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.dmi.dk/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » DMI - Dinamarca"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://hirlam.knmi.nl/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » HIRLAM - projeto conjunto"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cru.uea.ac.uk/cru/weather/index.htm"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » University of Edinburgh, Reino Unido Servidores Espelho"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.meteo.uni-koeln.de/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » University of Köln, Alemanha"}
          </a>
          <br />
          <a
            className={classes.root}
            href={
              "http://www.uni-sb.de/philfak/fb6/fr66/tpw/rem_sens/satfaq.html"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » University of Saarland, Alemanha"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.dkrz.de/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » DKRZ (Deutsches Klimarechenzentrum), Alemanha"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.cru.uea.ac.uk/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Climatic Research Unit, University of East Anglia, Reino Unido "
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.atm.ch.cam.ac.uk/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Centre for Atmospheric Science, University of Cambridge, Reino Unido"
            }
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.doc.ic.ac.uk/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Imperial College, London , Reino Unido -- Mapa do tempo de hoje"
            }
          </a>
          <br />
          <p>
            • <b>Kenia:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.meteo.go.ke/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Kenia Meteorological Departament"}
          </a>
          <br />
          <p>
            • <b>Niger:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.acmad.ne"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » ACMAD African Centre of Meteorological Applications for Development"
            }
          </a>
          <br />
          <p>
            • <b>Zimbabwe:</b>
          </p>
          <a
            className={classes.root}
            href={"http://weather.utande.co.zw/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Zimbabwe Meteorological Services Departament"}
          </a>
          <br />
          <p>
            • <b>China:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.nmc.gov.cn/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » NMC - Beijing National Meteorological Center"}
          </a>
          <br />
          <p>
            • <b>Japão:</b>
          </p>
          <a
            className={classes.root}
            href={"http://hydro.iis.u-tokyo.ac.jp/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              " » University of Tokyo, Musiake Laboratory - Imagens NOAA (Asia) &amp; GMS "
            }
          </a>
          <br />
          <p>
            • <b>Austrália:</b>
          </p>
          <a
            className={classes.root}
            href={"http://www.bom.gov.au/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {"     » Commonwealth Bureau of Meteorology"}
          </a>
          <br />
          <a
            className={classes.root}
            href={"http://www.csu.edu.au/weather.html"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            {
              "     » Charles Sturt University Previsão de tempo e Monitoramento global"
            }
          </a>
          <br />
        </Container>
      </DialogContent>
    </React.Fragment>
  );
}
