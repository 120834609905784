export const ADD_LAYER = "@layers/ADD";
export const REMOVE_LAYER = "@layers/REMOVE";
export const UPDATE_LAYER = "@layers/UPDATE";
export const UPDATE_TIME = "@layers/UPDATE_TIME";

export type Layers = {
  layer: string;
  time?: string;
  update?: boolean;
  lastUpdate?: string;
};

export interface ILayers {
  data: Layers[];
}

export type LayersActionAddTypes = {
  type: string;
  payload?: Layers;
};

export type LayersActionDeleteTypes = {
  type: string;
  payload?: string;
};

export type LayerActionTimerTypes = {
  type: string;
  payload?: {
    layer: string;
    time: string;
  };
};

export type LayerActionUpdateTypes = {
  type: string;
  payload?: {
    layer: string;
    update: boolean;
  };
};

export type LayersActionTypes =
  | LayersActionAddTypes
  | LayerActionUpdateTypes
  | LayerActionTimerTypes;
