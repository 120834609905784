import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import { ReactComponent as LogoRedemet } from "../../assets/svg/logo-redemet.svg";

interface Imodal {
  title: string;
  menu: string;
  url: string;
  linkExterno?: boolean;
}

export default function MenuModal(props: Imodal) {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
    setActive(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(false);
  };

  return (
    <React.Fragment>
      <li
        className={
          active
            ? "redemet-mainsidebar-menu-item is-active"
            : "redemet-mainsidebar-menu-item"
        }
        onClick={handleClickOpen}
      >
        {props.linkExterno ? (
          <a className="item-wrapper" title={props.title}>
            {props.menu}
          </a>
        ) : (
          <span className="item-wrapper" title={props.title}>
            {props.menu}
          </span>
        )}
      </li>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogActions>
          {<LogoRedemet />}
          <Typography variant="h5">
            {" - "}
            {props.title}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
        <DialogContent>
          <iframe src={props.url} height="800" width="100%" frameBorder="0" />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
