import produce from "immer";
import {
  ADD_LAYER,
  ILayers,
  LayersActionTypes,
  REMOVE_LAYER,
  UPDATE_LAYER,
  UPDATE_TIME,
} from "./types";
import { dateUtc } from "../../utils/dates";

const INITIAL_STATE: ILayers = {
  data: [],
};

const layersReducer = (
  state = INITIAL_STATE,
  action: LayersActionTypes
): ILayers => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_LAYER: {
        const { layer, ...others } = action.payload as any;
        const newData = {
          ...state.data,
        };
        (newData as any)[layer] = {
          ...others,
        };
        draft.data = newData;
        break;
      }
      case REMOVE_LAYER: {
        const layer = action.payload;
        const newData = {
          ...state.data,
        };

        const layers = Object.keys(state.data);

        if (layers.indexOf(layer as any) > -1) {
          delete newData[layer as any];
        }
        draft.data = newData;
        break;
      }
      case UPDATE_LAYER: {
        const { layer, update } = action.payload as any;
        const newData = {
          ...state.data,
        };

        (newData as any)[layer] = {
          ...(newData as any)[layer],
          lastUpdate: `${dateUtc(new Date())} Z`,
          update,
        };
        draft.data = newData;

        break;
      }
      case UPDATE_TIME: {
        const { layer, time } = action.payload as any;
        const newData = {
          ...state.data,
        };

        (newData as any)[layer] = {
          ...(newData as any)[layer],
          time,
        };
        draft.data = newData;

        break;
      }
      default:
        return state;
    }
  });
};

export default layersReducer;
