import {
  ChartDataSets,
  ChartOptions,
  ChartTooltipOptions,
  ChartYAxe,
  ChartTooltipItem,
} from "chart.js";

export interface ILine {
  labels: string[];
  titleLegendLeft?: string;
  legendPosition?: string;
  titleLegendRight?: string;
  ticksLeftMin?: number;
  ticksLeftMax?: number;
  ticksLeftStep?: number;
  ticksRightMin?: number;
  ticksRightMax?: number;
  ticksRightStep?: number;
  callbacks?: boolean;
  colorGrid?: string | undefined;
  horasPassadas?: number;
  sobreposto?: boolean;
  dados: any[];
}

export function mountDataset(
  newLabel: string,
  color: string,
  newData: any,
  id: string,
  stack: any = ""
) {
  const newDataset: ChartDataSets = {
    label: newLabel,
    fill: false,
    backgroundColor: "rgba(" + color + ",0.4)",
    borderColor: "rgba(" + color + ",1)",
    borderWidth: 1.5,
    hoverBackgroundColor: "rgba(" + color + ",0.6)",
    hoverBorderColor: "rgba(" + color + ",1)",
    showLine: true,
    data: newData,
  };
  if (id !== "") {
    newDataset["yAxisID"] = id;
  }
  if (stack !== "") {
    newDataset["stack"] = stack;
  }
  return newDataset;
}

export function mountOptions(props: ILine) {
  const {
    titleLegendLeft,
    titleLegendRight,
    ticksLeftMin,
    ticksLeftMax,
    ticksLeftStep,
    ticksRightMin,
    ticksRightMax,
    ticksRightStep,
    sobreposto,
    callbacks,
    colorGrid,
  } = props;

  let ticksLeft;
  let ticksRight;

  const tooltips: ChartTooltipOptions = {
    enabled: true,
    mode: "index",
    intersect: true,
  };

  if (callbacks !== undefined) {
    tooltips["callbacks"] = {
      label: function (tooltipItem: ChartTooltipItem, data) {
        let label =
          (data.datasets as any)[tooltipItem.datasetIndex as any].label || "";
        if (label) {
          label += ": ";
        }
        if (isNaN(tooltipItem.yLabel as any)) {
          label += 0;
        } else {
          label += 1;
        }
        return label;
      },
    };
  }

  if (!isNaN(ticksLeftMin as any) || !isNaN(ticksLeftMax as any)) {
    ticksLeft = {
      min: ticksLeftMin,
      max: ticksLeftMax,
    };
  }
  if (!isNaN(ticksLeftStep as any)) {
    (ticksLeft as any)["stepSize"] = ticksLeftStep;
  }

  if (!isNaN(ticksRightMin as any) || !isNaN(ticksRightMax as any)) {
    ticksRight = {
      min: ticksRightMin,
      max: ticksRightMax,
    };
  }
  if (!isNaN(ticksRightStep as any)) {
    (ticksRight as any)["stepSize"] = ticksRightStep;
  }

  const yAxesL: ChartYAxe = {
    display: true,
    gridLines: { color: colorGrid },
    stacked: sobreposto,
    scaleLabel: {
      display: true,
      labelString: titleLegendLeft,
    },
    position: "left",
    id: "LegendLeft",
  };
  if (ticksLeft !== undefined) {
    yAxesL["ticks"] = ticksLeft;
  }
  const yAxes = [yAxesL];

  if (titleLegendRight !== undefined) {
    if (ticksRightMin !== undefined || ticksRightMax !== undefined) {
      ticksRight = {
        min: ticksRightMin,
        max: ticksRightMax,
      };
    }
    if (ticksRightStep !== undefined) {
      (ticksRight as any)["stepSize"] = ticksRightStep;
    }

    const yAxesR: ChartYAxe = {
      display: true,
      scaleLabel: {
        display: true,
        labelString: titleLegendRight,
      },
      position: "right",
      id: "LegendRight",
      gridLines: {
        drawOnChartArea: false,
      },
    };
    if (ticksRight !== undefined) {
      yAxesR["ticks"] = ticksRight;
    }
    yAxes.push(yAxesR);
  }

  const newOptions: ChartOptions = {
    maintainAspectRatio: false,
    legendCallback: (chart: any) => {
      const list =
        chart &&
        chart.legend &&
        chart?.legend?.legendItems
          ?.map((value: any) => {
            return `<li><div class="line" style="background-color: ${value.strokeStyle}"><span style="background-color: ${value.strokeStyle}" class="bullet"></div>${value.text}</li>`;
          })
          .join("");
      return `<ul class="list-legends">${list}</ul>`;
    },
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: "#333",
        usePointStyle: true,
        boxWidth: 2,
      },
    },
    tooltips: tooltips,
    scales: {
      xAxes: [
        {
          gridLines: { color: colorGrid },
          stacked: true,
          ticks: {
            maxRotation: 0,
            autoSkip: true,
            autoSkipPadding: 6,
          },
        },
      ],
      yAxes: yAxes,
    },
  };
  return newOptions;
}
