import {
  ADD_COD,
  SET_GRID_HOUR,
  SET_VALIDADES,
  AerodromoActionTypes,
  CodAerodromoType,
  HourAerodromoType,
  ValidadesAerodromoType,
} from "./types";

export const addCod = (cod: CodAerodromoType): AerodromoActionTypes => {
  return {
    type: ADD_COD,
    payload: cod,
  };
};

export const setGridHour = (hour: HourAerodromoType): AerodromoActionTypes => {
  return {
    type: SET_GRID_HOUR,
    payload: hour,
  };
};

export const setValidades = (
  validades: ValidadesAerodromoType
): AerodromoActionTypes => {
  return {
    type: SET_VALIDADES,
    payload: validades,
  };
};
