import { combineReducers } from "redux";
import themeReducer from "./Theme/themeReducer";
import aerodromoReducer from "./Aerodromo/aerodromoReducer";
import menuReducer from "./Menu/menuReducer";
import layersReducer from "./Layers/layersReducer";
import sigmetReducer from "./Sigmet/SigmetReducer";

const rootReducer = combineReducers({
  theme: themeReducer,
  aerodromo: aerodromoReducer,
  menu: menuReducer,
  layers: layersReducer,
	sigmet: sigmetReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
