import React, { FunctionComponent } from "react";
import "./styles.css";

type props = {
  children: React.ReactNode;
};

const SidebarMenu: FunctionComponent<props> = ({ children }: props) => (
  <ul className="redemet-mainsidebar-menu">{children}</ul>
);

export default SidebarMenu;
