import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: function () {
    return true;
  },
});

Api.interceptors.request.use(
  (config) => {
    config.params = {
      ...config.params,
      api_key: process.env.REACT_APP_TOKEN_API,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
