import React, { useCallback, useEffect, useState } from "react";
import ControlDiv from "../../components/Leaflet/ControlDiv";
import useControlAnimation from "../../hooks/useControlAnimation";
import Control from "./components/Control";
import ImageOverlay from "./components/ImageOverlay";

type data = {
  path: string;
};

export type parameters = {
  label: string;
  param: any;
  legends?: React.ReactNode;
};
export interface IImage {
  latLon: {
    id: number;
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
  };
  tempo: string[];
  imagens: data[];
}

export interface IAnimationImageOverlay extends IImage {
  multi?: true;
  layer: string;
  checkedLayer: boolean;
  parameterUrlDefault: string;
  onChangeData?: (param: any) => void;
  parameters?: parameters[];
}

const AnimationImageOverlay = (props: IAnimationImageOverlay) => {
  const {
    checkedLayer,
    layer,
    imagens,
    latLon = {
      id: 0,
      lon_min: 0,
      lon_max: 0,
      lat_min: 0,
      lat_max: 0,
    },
    multi = false,
    onChangeData,
    tempo: propsTempo,
    parameters,
    parameterUrlDefault,
  } = props;

  const [downloadSuccess, setDownloadSucess] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastTimeLayer, setLastTimeLayer] = useState<string | undefined>("");

  const {
    opacity,
    velocidade,
    index,
    pause,
    play,
    running,
    changeIndex,
    changeOpacity,
    tempoIndex,
    changeVelocidade,
  } = useControlAnimation({
    totalImagens: downloadSuccess,
    tempo: propsTempo,
  });

  useEffect(() => {
    setLastTimeLayer(`${propsTempo[propsTempo.length - 1]} UTC`);
  }, [propsTempo]);

  useEffect(() => {
    if (!checkedLayer) {
      pause();
    }
  }, [checkedLayer, pause]);

  const changeDownloadSucess = useCallback((downloads: number): void => {
    if (downloads > 0) {
      setDownloadSucess(downloads);
      setLoading(false);
    }
  }, []);

  const changeParameters = useCallback(
    (parameters: any) => {
      if (onChangeData !== undefined) {
        pause();
        setLoading(true);
        onChangeData(parameters);
      }
    },
    [pause, onChangeData]
  );

  return (
    <>
      <ImageOverlay
        latLon={latLon}
        tempo={propsTempo}
        imageIndex={index}
        opacity={opacity}
        imagens={imagens}
        onDownloadSucess={changeDownloadSucess}
        multi={multi}
      />
      {imagens.length > 0 && checkedLayer && (
        <ControlDiv position={"topright"}>
          <Control
            layer={layer}
            lastTimeLayer={lastTimeLayer}
            totalImages={downloadSuccess}
            running={running}
            play={play}
            pause={pause}
            tempo={tempoIndex}
            velocidade={velocidade}
            onIndex={changeIndex}
            indexImage={index}
            valueOpacity={opacity}
            onOpacity={changeOpacity}
            onVelocidade={changeVelocidade}
            parametersUrl={parameters}
            onParameters={changeParameters}
            parametersUrlDefault={parameterUrlDefault}
            loading={loading}
          />
        </ControlDiv>
      )}
    </>
  );
};

export default AnimationImageOverlay;
