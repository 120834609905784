import React, { useEffect, useState } from "react";
import { parameters } from "../index";
import LayerControlContainer from "../../LayerControl/LayerControlContainer";
import TimelineControl from "../../LayerControl/TimelineControl";
import ChipList from "../../LayerControl/ChipList";
import AtualizacaoItem from "../../LayerControl/AtualizacaoItem";
import OpacityControl from "../../LayerControl/OpacityControl";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Loader from "../../Loader";

interface IControl {
  layer: string;
  running: boolean;
  play: () => void;
  pause: () => void;
  totalImages: number;
  indexImage: number;
  velocidade: number;
  valueOpacity: number;
  tempo: string | undefined;
  lastTimeLayer: string | undefined;
  parametersUrl?: parameters[];
  parametersUrlDefault?: string;
  onIndex: (index: number | number[]) => void;
  onOpacity: (index: number | number[]) => void;
  onVelocidade: (velocidade: number | number[]) => void;
  onParameters?: (parameters: string) => void;
  loading: boolean;
}

const Control = (props: IControl) => {
  const {
    indexImage,
    onIndex,
    lastTimeLayer,
    layer,
    loading,
    onOpacity,
    onParameters,
    parametersUrlDefault,
    parametersUrl,
    onVelocidade,
    valueOpacity,
    pause,
    play,
    running,
    totalImages,
    tempo,
  } = props;
  const [parametersValue, setParametersValue] =
    useState<parameters["param"]>("");
  const [legend, setLegend] = useState<any>([]);
  const [control1, setControl1] = React.useState<boolean>(false);

  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );

  useEffect(() => {
    if (props.parametersUrlDefault !== undefined) {
      setParametersValue(parametersUrlDefault);
    }
  }, [parametersUrlDefault, props.parametersUrlDefault]);

  useEffect(() => {
    setLegend(
      parametersUrl
        ?.filter((row) => row.param === parametersValue)
        .map((row) => row.legends && row.legends)
    );
  }, [parametersValue, parametersUrl]);

  function handlePlayPause() {
    if (running) {
      pause();
    } else {
      play();
    }
  }

  const handleChangeSlider = (event: any, newValue: number | number[]) => {
    onIndex(newValue as number);
  };

  const handleChangeOpacity = (event: any, newValue: number | number[]) => {
    onOpacity(newValue as number);
  };

  const handleChangeVelocidade = (newValue: number) => {
    onVelocidade(newValue as number);
  };

  const changeParameters = (parameters: string) => {
    if (onParameters !== undefined) {
      setParametersValue(parameters);
      onParameters(parameters);
    }
  };

  return (
    <>
      <LayerControlContainer
        title={layer}
        titleSuffix={lastTimeLayer}
        isActive={control1}
        toggle={setControl1}
        loading={loading}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {layerRedux !== undefined && (
              <AtualizacaoItem
                layer={layer}
                contador={layerRedux.time}
                data={layerRedux.lastUpdate}
              />
            )}

            <TimelineControl
              timeValue={indexImage}
              min={0}
              step={1}
              maxTimeValue={totalImages - 1}
              timeLabel={`${tempo}Z`}
              onTimelineChange={handleChangeSlider}
              handlePlayPause={() => handlePlayPause()}
              onVelocityChange={handleChangeVelocidade}
            />
            <ChipList
              items={parametersUrl}
              parametersValue={parametersValue}
              onClickItemIndex={changeParameters}
              uniqueValue={true}
            />
            <OpacityControl
              opacityValue={valueOpacity}
              step={0.1}
              minValue={0}
              maxValue={1}
              onOpacityChange={handleChangeOpacity}
            />

            {legend !== undefined && legend?.map((row: any) => row)}
          </>
        )}
      </LayerControlContainer>
    </>
  );
};

export default Control;
