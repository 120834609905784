import React, { FC, useCallback, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { Circle, Polygon, Popup } from "react-leaflet";
import { ISigmet } from "../../../repositories/Mensagens";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSnackbar } from "notistack";

function decodificaRaio(value: string) {
  const expNM = new RegExp("[0-90-90-9]+NM");
  const expKM = new RegExp("[0-90-90-9]+KM");
  let raio;
  let distancia;
  if (expNM.test(value)) {
    const myArray = expNM.exec(value);
    if (myArray) {
      distancia = myArray[0];
      const nm = parseInt(distancia.replace("NM", ""));
      raio = nm * 1852;
      return raio;
    }
  } else if (expKM.test(value)) {
    const myArray = expKM.exec(value);
    if (myArray) {
      distancia = myArray[0];
      const nm = parseInt(distancia.replace("KM", ""));
      raio = nm * 1000;
      return raio;
    }
  } else {
    return 0;
  }
}

interface SigmetType extends React.ComponentProps<any> {
  data: ISigmet[];
}

const Sigmet: FC<SigmetType> = ({ data }) => {
  const snackbar = useSnackbar();

  const copyClipboard = useCallback(
    (text) => {
      navigator.clipboard.writeText(text);
      snackbar.enqueueSnackbar("Copiado com sucesso", {
        variant: "success",
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    [snackbar]
  );

  const SigmetMap = useMemo(() => {
    return data.map((obj, index) => {
      if (obj.fenomeno !== "TC") {
        return (
          <Polygon key={index} color={obj.fenomeno_cor} positions={obj.lat_lon}>
            <Popup>
              <Typography variant="button" display="block" gutterBottom>
                {obj.title}
              </Typography>
              <Typography variant="body2" display="block" gutterBottom>
                {obj.fenomeno_comp}
              </Typography>
              <Tooltip title={obj.mens} aria-label="mens">
                <Button
                  onClick={() => copyClipboard(obj.mens)}
                  color={"primary"}
                  size={"small"}
                >
                  Copiar Mensagem
                </Button>
              </Tooltip>
            </Popup>
          </Polygon>
        );
      }
    }) as any;
  }, [data, copyClipboard]);

  const CicloneMap = useMemo(() => {
    return data.map((obj, index) => {
      if (obj.fenomeno === "TC") {
        const raio = decodificaRaio(obj.mens);
        return (
          <Circle
            key={index}
            color={"#000000"}
            center={obj.lat_lon[0]}
            radius={raio}
          >
            <Popup>
              <Typography variant="button" display="block" gutterBottom>
                {obj.title}
              </Typography>
              <Typography variant="body2" display="block" gutterBottom>
                {obj.fenomeno_comp}
              </Typography>
              <Tooltip title={obj.mens} aria-label="mens">
                <Button
                  onClick={() => copyClipboard(obj.mens)}
                  color={"primary"}
                  size={"small"}
                >
                  Copiar Mensagem
                </Button>
              </Tooltip>
            </Popup>
          </Circle>
        );
      }
    }) as any;
  }, [data, copyClipboard]);

  return (
    <>
      {SigmetMap}
      {CicloneMap}
    </>
  );
};

export default Sigmet;
