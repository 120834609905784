import { AxiosInstance, AxiosResponse } from "axios";
import Api from "../config/Api";
import { LatLngExpression } from "leaflet";

interface Iresource {
  readonly status: boolean;
  readonly message: string;
}

export interface IMeteograma extends Iresource {
  readonly data: {
    id_localidade: string;
    validade_inicial_horizontal: string[];
    validade_inicial: string[];
    vnt_dir: string[];
    vnt_vel: string[];
    vnt_rajada: string[];
    vnt_vrb: string[];
    vis: string[];
    vis_setor: string[];
    vis_setor_dir: string[];
    nevoeiro: string[];
    chuvisco: string[];
    chuva: string[];
    pancada: string[];
    trovoada: string[];
    granizo: string[];
    fumaca: string[];
    nevoa_umida: string[];
    nevoa_seca: string[];
    neve: string[];
    teto: string[];
    cobertura: string[];
    vv: string[];
    tt: string[];
    po: string[];
    ur: string[];
    qnh: string[];
    validade_primeira: string;
    validade_ultima: string;
    validade_atual: string;
    validade_total: number;
    horas_passado: number;
    horas_futuro: number;
    horas_futuro_max: number;
  };
}

export interface ISigmet {
  id_fir: string;
  validade_inicial: string;
  validade_final: string;
  title: string;
  mens: string;
  no_mens: string;
  fenomeno: string;
  fenomeno_comp: string;
  fenomeno_cor: string;
  fenomeno_transparencia: string;
  lat_lon: LatLngExpression[];
}

export interface IAirmet {
  id_fir: string;
  validade_inicial: string;
  validade_final: string;
  title: string;
  mens: string;
  no_mens: string;
  fenomeno: string;
  fenomeno_comp: string;
  fenomeno_cor: string;
  fenomeno_transparencia: string;
  lat_lon: LatLngExpression[];
}

export interface ISpeci {
  id_fir: string;
  validade_inicial: string;
  validade_final: string;
  title: string;
  mens: string;
  no_mens: string;
  fenomeno: string;
  fenomeno_comp: string;
  fenomeno_cor: string;
  fenomeno_transparencia: string;
  lat_lon: LatLngExpression[];
}

export interface IAviso {
  id_fir: string;
  validade_inicial: string;
  validade_final: string;
  mens: string;
}

export const MeteogramaInitInitial: IMeteograma = {
  status: false,
  message: "",
  data: {
    id_localidade: "",
    validade_inicial_horizontal: [],
    validade_inicial: [],
    vnt_dir: [],
    chuva: [],
    chuvisco: [],
    cobertura: [],
    fumaca: [],
    granizo: [],
    neve: [],
    nevoa_seca: [],
    nevoa_umida: [],
    nevoeiro: [],
    pancada: [],
    po: [],
    ur: [],
    qnh: [],
    teto: [],
    trovoada: [],
    tt: [],
    vis: [],
    vis_setor: [],
    vis_setor_dir: [],
    vnt_rajada: [],
    vnt_vel: [],
    vnt_vrb: [],
    vv: [],
    validade_primeira: "",
    validade_ultima: "",
    validade_atual: "",
    validade_total: 0,
    horas_passado: 0,
    horas_futuro: 0,
    horas_futuro_max: 0,
  },
};

export class Mensagens {
  constructor(private http: AxiosInstance, protected resource: string) {}

  meteograma<T = any>(cod: string, horas: number[]): Promise<AxiosResponse<T>> {
    return this.http.get<T>(
      `${this.resource}/meteograma/${cod}?horas=${Math.abs(
        horas[0]
      )}&futuro=true&futuro_horas=${horas[1]}`
    );
  }

  sigmet<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/sigmet`);
  }

  airmet<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/airmet`);
  }

  speci<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/speci`);
  }

  avisoPais<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/aviso/pais/list`);
  }
}

const mensagensRepositories = new Mensagens(Api, "mensagens");
export default mensagensRepositories;
