import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface Props {
  columns: string[];
  data: any[][];
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      verticalAlign: "middle",
    },
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 12,
    },
    sizeSmall: {
      padding: "0px 5px 0px 5px",
    },
    body: {
      fontSize: 11,
    },
  })
)(TableCell);

const extensionImage = [".svg", ".png", ".gif"];

function isHexColor(hex: string) {
  return (
    typeof hex === "string" && hex.length === 6 && !isNaN(Number("0x" + hex))
  );
}

function isImage(img: string) {
  const extension = img.slice(-4);
  if (extensionImage.includes(extension)) {
    return <img src={img} width={35} />;
  }
  return false;
}

const LineLegend = (props: Props) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {props.columns.map((row, index) => {
                return <StyledTableCell key={index}>{row}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((rows, index) => (
              <TableRow key={index}>
                {rows.map((row, i) => {
                  let style;

                  if (isHexColor(row)) {
                    style = {
                      background:
                        "linear-gradient(to right, #" +
                        `${row}` +
                        " 90%, white 10%)",
                    };
                  }


									return (
                    <StyledTableCell key={i} style={style}>
                      {isImage(row) ? isImage(row) : !isHexColor(row) && row}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(LineLegend);
