import React, { useState, useRef, useCallback } from "react";
import produtosRepositories, {
  ISatelite,
  SateliteInitial,
} from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import AnimationImageOverlay from "../../components/AnimationImageOverlay";
import GradientLegend from "../../components/LayerControl/GradientLegend";
import useLayerChecked from "../../hooks/useLayerChecked";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import { SATELITE } from "../../config";

const layer = SATELITE;
const paramsDefault = "realcada";

const params = [
  {
    label: "Realçada",
    param: "realcada",
    legends: (
      <GradientLegend
        title="Realçada"
        endTitle="Temperatura(ºC)"
        valuesList={[
          { label: "45", color: ["#0D0D0D", "#D7D7D7"], scale: 2 },
          { label: "-30", color: ["#D7D7D7", "#FF9900"], scale: 1 },
          { label: "-40", color: ["#FF9900", "#FEFE00"], scale: 1 },
          { label: "-50", color: ["#FEFE00", "#00CCFE"], scale: 1 },
          { label: "-60", color: ["#00CCFE", "#00CCFE"], scale: 1 },
          { label: "-70", color: ["#0000FE", "#0000FE"], scale: 1 },
          { label: "-80", color: ["#FF99CC", "#FF99CC"], scale: 1 },
          { label: "-90", color: ["#FF0405"], scale: 1 },
        ]}
      />
    ),
  },
  {
    label: "Infra-vermelho",
    param: "ir",
    legends: (
      <GradientLegend
        title="IR (InfraVermelho)"
        endTitle="Temperatura(ºC)"
        valuesList={[
          { label: "45", color: ["#040404"], scale: 1 },
          { label: "19", color: ["#040404"], scale: 1 },
          { label: "-07", color: ["#BABABA"], scale: 1 },
          { label: "-33", color: ["#BABABA"], scale: 1 },
          { label: "-59", color: ["#BABABA"], scale: 1 },
          { label: "-85", color: ["#BABABA"], scale: 1 },
        ]}
      />
    ),
  },
  {
    label: "Visual",
    param: "vis",
    legends: (
      <GradientLegend
        title="Visual"
        endTitle="Fator Reflectância em (%)"
        valuesList={[
          { label: "0", color: ["#040404"], scale: 1 },
          { label: "20", color: ["#040404"], scale: 1 },
          { label: "40", color: ["#BABABA"], scale: 1 },
          { label: "60", color: ["#BABABA"], scale: 1 },
          { label: "80", color: ["#BABABA"], scale: 1 },
        ]}
      />
    ),
  },
];

const Satelite = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<ISatelite>(SateliteInitial);
  const [parametersSelected, setParametersSelected] = useState(paramsDefault);

  const { checkedLayer } = useLayerChecked({ layer });

  const getSatelite = useCallback(async (parameters = paramsDefault) => {
    setParametersSelected(parameters);
    try {
      const { data } = await produtosRepositories.satelite<
        IResponse<ISatelite>
      >(parameters);
      if (subscribed.current) {
        if (data.data.satelite !== undefined) {
          setData(data.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useLayerUpdated({
    onLoad: getSatelite,
    seconds: 1800,
    checkedLayer,
    layer,
    parameters: parametersSelected,
  });

  // useEffect(() => {
  //   subscribed.current = true;
  //   if (checkedLayer) {
  //     getSatelite();
  //   }
  //   return () => {
  //     subscribed.current = false;
  //   };
  // }, [checkedLayer]);

  return (
    <AnimationImageOverlay
      checkedLayer={checkedLayer}
      layer={layer}
      parameterUrlDefault={paramsDefault}
      latLon={data.lat_lon}
      tempo={data.anima}
      imagens={data.satelite}
      onChangeData={getSatelite}
      parameters={params}
    />
  );
};

export default Satelite;
