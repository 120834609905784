import React, { useState } from "react";
import { LatLngLiteral } from "leaflet";
import { useMapEvents } from "react-leaflet";
import ControlDiv from "./ControlDiv";
import { Box, Typography } from "@material-ui/core";

const Position = (props) => {
  const [position, setPosition] = useState<LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  useMapEvents({
    mousemove(e) {
      setPosition(e.latlng);
    },
  });

  return (
    <ControlDiv position={"bottomright"}>
      <Box
        {...props}
        style={{
          fontWeight: "bold",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 2,
          borderRadius: 5,
        }}
      >
        <Typography variant="subtitle2">
          Lat: {position.lat.toFixed(2)}
        </Typography>
        <Typography variant="subtitle2">
          Lon: {position.lng.toFixed(2)}
        </Typography>
      </Box>
    </ControlDiv>
  );
};

export default React.memo(Position);
