import { add, format } from "date-fns";

export const dateUtc = (date: Date): string => {
  const difeTimeZone = date.getTimezoneOffset();
  const dateUtc = format(
    add(date, {
      minutes: difeTimeZone,
    }),
    "HH:mm"
  );

  return dateUtc;
};
