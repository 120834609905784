import React, { useCallback, useEffect, useState } from "react";
import { IImage } from "../index";
import { ImageOverlay as LeafletImageOverlay } from "react-leaflet";
import { LeafletEvent } from "leaflet";

interface ImageOverlayProps extends IImage {
  velocidade?: number;
  imageIndex: number;
  opacity: number;
  tipo?: string;
  onDownloadSucess(downloads: number): void;
  multi: boolean | undefined;
}

const ImageOverlay: React.FC<ImageOverlayProps> = (
  props: ImageOverlayProps
) => {
  const {
    imagens,
    multi,
    latLon,
    opacity,
    imageIndex,
    onDownloadSucess,
    tipo,
  } = props;

  const [totalImagensApi, setTotalImagensApi] = useState<number>(0);
  const [totalImagensDownload, setTotalImagensDownload] = useState<number>(0);
  const [imgDownloadSuccess, setImgDownloadSucess] = useState<number[]>([]);
  const [opacityArray, setOpacityArray] = useState<number[]>([]);

  const getTotalImagens = useCallback(() => {
    let length = 0;
    if (multi) {
      imagens.forEach((e: any) => {
        length += e.length;
      });
    } else {
      length = imagens.length;
    }
    setTotalImagensApi(length);
  }, [imagens, multi]);

  const addOpacityArray = useCallback(() => {
    setOpacityArray(new Array(imagens.length).fill(0));
  }, [imagens]);

  const checkImagemDownload = useCallback(() => {
    setTotalImagensDownload((prevState) => prevState + 1);
  }, []);

  /**
   * Adiciona o indice das imagens baixadas com sucesso.
   * @param posicao : number
   */
  const downloadSucces = useCallback(
    (posicao: number) => {
      checkImagemDownload();
      setImgDownloadSucess((prevState) =>
        prevState.indexOf(posicao) !== -1
          ? prevState
          : prevState.concat(posicao)
      );
    },
    [checkImagemDownload]
  );

  /**
   * Apaga a camada da imagem que retorna error
   * @param e: LeafletEvent
   */
  const errorDownloadImage = useCallback(
    (e: LeafletEvent) => {
      checkImagemDownload();
      e.target.remove();
    },
    [checkImagemDownload]
  );

  const changeOpacityIndex = useCallback(
    (imageIndex: number) => {
      if (imgDownloadSuccess.length > 0) {
        const newOpacity = opacityArray.fill(0);
        newOpacity[imgDownloadSuccess[imageIndex]] = opacity;
        setOpacityArray(newOpacity);
      }
    },
    [imgDownloadSuccess, opacity, opacityArray]
  );

  /**
   * Seta o último valor do index da imagem
   * para aparecer inicialmente na tela
   */
  const setLastImageOpacityIndex = useCallback(() => {
    const lastImagemIndex = imgDownloadSuccess.length - 1;
    changeOpacityIndex(lastImagemIndex);
  }, [imgDownloadSuccess, changeOpacityIndex]);

  useEffect(() => {
    if (totalImagensDownload > 0) {
      setTotalImagensDownload(0);
      setImgDownloadSucess([]);
    }
    if (multi) {
      imagens.map((obj, i) =>
        (obj as any).map((row: any) => {
          downloadImage(row.path, i);
        })
      );
    } else {
      imagens.map((row, index) => {
        downloadImage(row.path, index);
      });
    }

    function downloadImage(url: string, index: number) {
      let error = false;
      const img = new Image();
      img.src = url;
      img.onerror = function () {
        error = true;
      };
      if (!error) {
        downloadSucces(index);
      }
    }
  }, [imagens, downloadSucces, multi, totalImagensDownload]);

  /**
   * Método para saber o total de imagens
   * retornado pela API
   */
  useEffect(() => {
    if (imagens.length > 0) {
      getTotalImagens();
      addOpacityArray();
    }
    return () => {
      setTotalImagensApi(0);
    };
  }, [imagens, addOpacityArray, getTotalImagens]);

  useEffect(() => {
    if (
      totalImagensApi === totalImagensDownload &&
      imgDownloadSuccess.length > 0
    ) {
      orderImageDownload();
      setLastImageOpacityIndex();
      onDownloadSucess(imgDownloadSuccess.length);
    }

    function orderImageDownload() {
      const newImgDownload = imgDownloadSuccess;
      newImgDownload.sort((a, b) => a - b);
      setImgDownloadSucess(newImgDownload);
    }
  }, [
    totalImagensApi,
    imagens,
    imgDownloadSuccess,
    onDownloadSucess,
    totalImagensDownload,
    setLastImageOpacityIndex,
  ]);

  useEffect(() => {
    changeOpacityIndex(imageIndex);
  }, [imageIndex, changeOpacityIndex]);

  if (
    totalImagensApi === totalImagensDownload &&
    imgDownloadSuccess.length > 0
  ) {
    if (multi) {
      return (
        <>
          {imagens.map((obj, i) =>
            (obj as any).map((row: any, index: any) => (
              <LeafletImageOverlay
                key={`${tipo}-${index}`}
                bounds={[
                  [row.lat_min, row.lon_min],
                  [row.lat_max, row.lon_max],
                ]}
                url={
                  row.path === null
                    ? "https://estatico-redemet.decea.gov.br/radar/nodata.png"
                    : row.path
                }
                eventHandlers={{
                  error: (e) => {
                    errorDownloadImage(e);
                  },
                }}
                opacity={opacityArray[i]}
              />
            ))
          )}
        </>
      );
    } else {
      return (
        <>
          {imagens.map((row, index) => (
            <LeafletImageOverlay
              key={`${tipo}-${index}`}
              bounds={[
                [latLon.lat_min, latLon.lon_min],
                [latLon.lat_max, latLon.lon_max],
              ]}
              url={row?.path}
              eventHandlers={{
                error: (e) => {
                  errorDownloadImage(e);
                },
              }}
              opacity={opacityArray[index]}
            />
          ))}
        </>
      );
    }
  } else {
    return null;
  }
};

export default ImageOverlay;
