import { Box, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { FunctionComponent } from "react";
import "./styles.css";

import MCircularProgress from "../Material/MCircularProgress";

type props = {
  children: React.ReactNode;
  title: string;
  titleSuffix?: string;
  isActive: boolean;
  toggle: (v: boolean) => void;
  loading?: boolean;
};

const LayerControlContainer: FunctionComponent<props> = ({
  children,
  title,
  titleSuffix,
  isActive,
  toggle,
  loading,
}: props) => {
  return (
    <div
      className={
        isActive
          ? "layer-control layer-control-container is-active"
          : "layer-control layer-control-container-hidden"
      }
    >
      <div className="header">
        <div className={isActive ? "title" : "title-hidden"}>{title}</div>
        {titleSuffix && (
          <div className={isActive ? "title-suffix" : "title-suffix-hidden"}>
            {loading ? (
              <Box style={{ marginTop: 4 }}>
                <MCircularProgress size={13} />
              </Box>
            ) : (
              titleSuffix
            )}
          </div>
        )}
        <div className="header-spacer"></div>
        <IconButton
          size="small"
          color="default"
          aria-label="Fechar controlador"
          onClick={() => toggle(!isActive)}
          disabled={loading}
        >
          {isActive ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      <div className="layer-control-content">
        <OverlayScrollbarsComponent
          className={`scroller`}
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <div className="content">{children}</div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
};

export default React.memo(LayerControlContainer);
