import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { ReactComponent as LogoRedemet } from "../../assets/svg/logo-redemet.svg";
import "./HorizontalMenu.css";
import AppContentDialog from "../AppContentDialog";
import Typography from "@material-ui/core/Typography";

interface IHorizontalMenuItem {
  title: string;
  description: string[];
  item?: string | React.ReactNode;
  itemClass?: string;
}

interface IHorizontalMenu extends React.PropsWithChildren<any> {
  items: IHorizontalMenuItem[];
  mainMenuIsOpen?: boolean;
  time?: string;
  lastUpdate?: string;
}

export default function HorizontalMenu({
  mainMenuIsOpen,
  items,
  children,
  time,
  lastUpdate,
}: IHorizontalMenu) {
  const [show, setShow] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: [""],
  });

  const handleHorizontalMenuItemClick = ({ index }) => {
    const data = items[index];
    setContentDialog({
      title: data.title,
      description: data.description,
    });
    switch (index) {
      default:
        setShowDialog(true);
    }
  };

  return (
    <>
      <AppContentDialog
        showDialog={showDialog}
        title={contentDialog.title}
        onClose={(v) => setShowDialog(v)}
      >
        {contentDialog.description.length > 0 ? (
          contentDialog.description.map((row, index) => (
            <Typography
              gutterBottom
              key={index}
              style={{ marginBottom: "10px" }}
            >
              {row}
            </Typography>
          ))
        ) : (
          <Typography gutterBottom style={{ marginBottom: "10px" }}>
            Sem informações.
          </Typography>
        )}
      </AppContentDialog>
      <div>
        <div
          className={`horizontal-menu ${
            mainMenuIsOpen ? "horizontal-menu-active" : ""
          }`}
        >
          <div className="horizontal-menu-logo">
            <div className="horizontal-menu-trigger">
              <IconButton onClick={() => setShow(!show)}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className="logo">
              <LogoRedemet />
            </div>
          </div>
          <ul className={`horizontal-menu-wrapper ${show && "show"}`}>
            {items.map((item, index) => (
              <li
                key={index}
                className={`horizontal-menu-item ${item.itemClass ?? ""}`}
                onClick={() => handleHorizontalMenuItemClick({ index })}
              >
                <span>{item.item}</span>
              </li>
            ))}
            <li className="horizontal-menu-item horizontal-menu-item-spacer"></li>
            {time && (
              <li className="horizontal-menu-item horizontal-menu-item-static">
                <div style={{ textAlign: "center" }}>
                  <b>Próxima Atualização</b>:
                  <br />
                  {time}
                </div>
              </li>
            )}
            {lastUpdate && (
              <li className="horizontal-menu-item horizontal-menu-item-static">
                <div style={{ textAlign: "center" }}>
                  <b>Última Atualização</b>:
                  <br />
                  {lastUpdate}
                </div>
              </li>
            )}
          </ul>
        </div>
        {children}
      </div>
    </>
  );
}
