import { useTimer } from "react-timer-hook";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  addLayer,
  updateLayer,
  updateTime,
} from "../store/Layers/actionCreatores";
import { Layers } from "../store/Layers/types";
import { dateUtc } from "../utils/dates";

type Props = {
  layer: string;
  checkedLayer: boolean;
  seconds: number;
  parameters?: string;
  onLoad: (value?: string) => void;
};

const useLayerUpdated = ({
  checkedLayer,
  layer,
  onLoad,
  parameters,
  seconds: propsSeconds,
}: Props) => {
  const dispatch = useDispatch();
  const [continueTimer, setContinueTimer] = useState(false);

  const time = useCallback(() => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + propsSeconds);
    return date;
  }, [propsSeconds]);

  const { seconds, minutes, pause, restart } = useTimer({
    expiryTimestamp: time(),
    onExpire: () => setContinueTimer(true),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pauseMemoized = useCallback(pause, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restartMemoized = useCallback(restart, []);

  const reloadTime = useCallback(() => {
    if (parameters) {
      onLoad(parameters);
    } else {
      onLoad();
    }

    restartMemoized(time());
    dispatch(updateLayer(layer, false));
  }, [parameters, layer, time, dispatch, onLoad, restartMemoized]);

  /** Gatilho usado para reiniciar o contador automaticamente */
  useEffect(() => {
    if (continueTimer) {
      setContinueTimer(false);
    }
    reloadTime();
  }, [continueTimer, reloadTime]);

  useEffect(() => {
    if (checkedLayer) {
      const layerAdd: Layers = {
        layer: layer,
        time: "00:00",
        lastUpdate: `${dateUtc(new Date())} Z`,
        update: false,
      };

      onLoad();
      dispatch(addLayer(layerAdd));
    }
  }, [checkedLayer, onLoad, dispatch, layer]);

  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );

  useEffect(() => {
    if (!checkedLayer) {
      pauseMemoized();
    } else {
      restartMemoized(time());
    }
    return () => {
      pauseMemoized();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedLayer, pauseMemoized, restartMemoized]);

  useEffect(() => {
    if (checkedLayer) {
      dispatch(
        updateTime(
          layer,
          `${("00" + minutes).slice(-2)}:${("00" + seconds).slice(-2)}`
        )
      );
    }
  }, [seconds, checkedLayer, layer, minutes, dispatch]);

  useEffect(() => {
    if (layerRedux !== undefined) {
      if (layerRedux.update) {
        reloadTime();
      }
    }
  }, [layerRedux?.update, layerRedux, reloadTime]);

  //return { setLayerRedux };
};

export default useLayerUpdated;
