import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import LogoINMET from "../../../assets/images/inmet.png";
import LogoCEMADEN from "../../../assets/images/cemaden.png";
import LogoINPE from "../../../assets/images/inpe.png";
import LogoCPTEC from "../../../assets/images/cptec.png";

export default function Links() {
  return (
    <React.Fragment>
      <DialogContent>
        <Container maxWidth="lg">
          <img
            src={LogoINMET}
            alt="Logo INMET"
            style={{ marginLeft: "25px", width: "150px" }}
          />
          <img
            src={LogoCEMADEN}
            alt="Logo CEMADEN"
            style={{ marginLeft: "25px", height: "150px" }}
          />
          <img
            src={LogoINPE}
            alt="Logo INPE"
            style={{ marginLeft: "25px", height: "150px" }}
          />
          <img
            src={LogoCPTEC}
            alt="Logo CPTEC"
            style={{ marginLeft: "25px", width: "150px" }}
          />
        </Container>
      </DialogContent>
    </React.Fragment>
  );
}
