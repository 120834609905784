import React, { FunctionComponent } from "react";
import "./styles.css";

type props = {
  children: React.ReactNode;
};

const SidebarSection: FunctionComponent<props> = ({ children }: props) => (
  <div className="redemet-mainsidebar-section">{children}</div>
);

export default SidebarSection;
