import React, { useState, useRef, useCallback } from "react";
import produtosRepositories, {
  IRadar,
  RadarInitial,
} from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import UseLayerChecked from "../../hooks/useLayerChecked";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import { RADAR } from "../../config";
import Animation from "./components/Animation";
import LegendRadar from "./components/LegendRadar";

const layer = RADAR;
const paramsDefault = "maxcappi";

// function Legend() {
//   return (
//     <GradientLegend
//       title="Refletividade (dBZ)"
//       endTitle="Potencial de Precipitação (mm/h)"
//       topLabels={["M. Fraco", "Fraco", "Moderado", "Forte", "M.Forte"]}
//       valuesList={[
//         { label: "0", color: ["#75FFFF", "#0000F6"], scale: 1 },
//         { label: "1", color: ["#00FF00", "#009900"], scale: 1 },
//         { label: "3", color: ["#FFFF00", "#FF9900"], scale: 1 },
//         { label: "25", color: ["#FF0000", "#C40001"], scale: 1 },
//         { label: ">100", color: ["#FF00FF", "#9758C9"], scale: 1 },
//       ]}
//       valuesListTop={[
//         { value: "0", scale: 1, label: "M. Fraco" },
//         { value: "20", scale: 1, label: "Fraco" },
//         { value: "30", scale: 1, label: "Moderado" },
//         { value: "45", scale: 1, label: "Forte" },
//         { value: "63", scale: 1, label: "" },
//         { value: "75", scale: 1, label: "M. Forte" },
//       ]}
//     />
//   );
// }

const params = [
  {
    label: "Maxcappi",
    param: "maxcappi",
    legends: <LegendRadar />,
  },
  {
    label: "10KM",
    param: "10km",
    legends: <LegendRadar />,
  },
  {
    label: "07KM",
    param: "07km",
    legends: <LegendRadar />,
  },
  {
    label: "05KM",
    param: "05km",
    legends: <LegendRadar />,
  },
  {
    label: "03KM",
    param: "03km",
    legends: <LegendRadar />,
  },
];

const Radares = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<IRadar>(RadarInitial);
  const { checkedLayer } = UseLayerChecked({ layer });
  const [parametersSelected, setParametersSelected] = useState(paramsDefault);

  const getData = useCallback(async (parameters = paramsDefault) => {
    setParametersSelected(parameters);
    try {
      const { data } = await produtosRepositories.radar<IResponse<IRadar>>(
        parameters
      );
      if (subscribed.current) {
        if (data.data.radar !== undefined) {
          setData(data.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useLayerUpdated({
    onLoad: getData,
    seconds: 200,
    checkedLayer,
    layer,
    parameters: parametersSelected,
  });

  // useEffect(() => {
  //   subscribed.current = true;
  //   if (checkedLayer) {
  //     getData();
  //     setLayerRedux();
  //   }
  //   return () => {
  //     subscribed.current = false;
  //   };
  // }, [checkedLayer, getData]);

  return (
    <>
      <Animation
        checkedLayer={checkedLayer}
        layer={layer}
        parameterUrlDefault={paramsDefault}
        latLon={{
          id: 0,
          lon_min: 0,
          lon_max: 0,
          lat_min: 0,
          lat_max: 0,
        }}
        multi={true}
        tempo={data.anima}
        imagens={data.radar}
        onChangeData={getData}
        parameters={params}
      />
    </>
  );
};

export default Radares;
