import { CheckActionTypes } from "./types";


const INITIAL_STATE: string[] = ['VA CLD','RDOACT CLD','ICE','TC','TS','TURB','VA'];
const sigmetReducer = (state =INITIAL_STATE, action: CheckActionTypes): string[] => {
  switch (action.type) {
    case "CHECK_SIGMET":
      return action.payload;

    default:
      return state;
  }
};

export default sigmetReducer;
