import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import aerodromosRepositories, { IStatus } from "../../repositories/Aeroportos";
import { icon } from "leaflet";
import { Marker, Tooltip, useMapEvents } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import Sidebar from "./components/Sidebar";
import { Informacao } from "./Informacao";
import { CodAerodromoType } from "../../store/Aerodromo/types";
import { useDispatch, useSelector } from "react-redux";
import { addCod } from "../../store/Aerodromo/actionCreatores";
import { menuToggle } from "../../store/Menu/actionCreatores";
import ControlDiv from "../../components/Leaflet/ControlDiv";

import Green from "../../assets/aerodromos/green.svg";
import GreenW from "../../assets/aerodromos/greenw.svg";
import Red from "../../assets/aerodromos/red.svg";
import RedW from "../../assets/aerodromos/redw.svg";
import Yellow from "../../assets/aerodromos/yellow.svg";
import YellowW from "../../assets/aerodromos/yelloww.svg";
import Cinza from "../../assets/aerodromos/cinza.svg";

import useLayerChecked from "../../hooks/useLayerChecked";
import { AERODROMOS } from "../../config";
import LayerControlContainer from "../../components/LayerControl/LayerControlContainer";
import LineLegend from "../../components/LayerControl/LineLegend";
import { dateUtc } from "../../utils/dates";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import AtualizacaoItem from "../../components/LayerControl/AtualizacaoItem";
import { RootState } from "../../store";
import { Typography } from "@material-ui/core";
import nl2br from "react-nl2br";
import LegendStatus from "./components/LegendStatus";

const columnsLegend = ["Visiblidade", "Condição", "Teto(ft)", "Cor"];
const dataLegend = [
  [">=5000", "e", ">=1500", "assets/aerodromos/twogreen.svg"],
  [
    "<5000 e >= 1500",
    "e/ou",
    "<1500 e >= 600",
    "assets/aerodromos/twoyellow.svg",
  ],
  ["<1500 metros", "e/ou", "<600ft", "assets/aerodromos/twored.svg"],
];

const paises =
  "argentina,bolivia,brasil,chile,colombia,ecuador,guyana,paraguay,peru,surinam,uruguay,venezuela";

interface StatusProps extends React.ComponentProps<any> {
  active?: boolean;
}

export const Status: FC<StatusProps> = ({ active = false, ...props }) => {
  const layer = AERODROMOS;
  const subscribed = useRef(true);
  const dispatch = useDispatch();
  const [data, setData] = useState<IStatus>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sizeIcon, setSizeIcon] = useState<L.PointExpression>([12, 12]);
  const [control, setControl] = React.useState<boolean>(false);
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });
  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );

  const getStatus = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await aerodromosRepositories.status<IStatus>(paises);
      if (subscribed.current) {
        setData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Chamada direta pelo componente exibindo sem precisar ativar pelo menu
  useEffect(() => {
    if (active) {
      getStatus();
    }
  }, [active, getStatus]);

  useLayerUpdated({
    onLoad: getStatus,
    seconds: 300,
    checkedLayer: checkedLayer || active,
    layer,
  });

  useMapEvents({
    zoom(e) {
      const size = e.sourceTarget._zoom * 4;
      setSizeIcon([size, size]);
    },
  });

  const iconColor = useCallback(
    (color: string) => {
      let iconMarkup;
      switch (color) {
        case "g":
          iconMarkup = Green;
          break;
        case "gw":
          iconMarkup = GreenW;
          break;
        case "r":
          iconMarkup = Red;
          break;
        case "rw":
          iconMarkup = RedW;
          break;
        case "y":
          iconMarkup = Yellow;
          break;
        case "yw":
          iconMarkup = YellowW;
          break;
        case "cinza":
          iconMarkup = Cinza;
          break;
      }

      const customMarkerIcon = icon({
        iconUrl: iconMarkup,
        iconSize: sizeIcon,
      });
      return customMarkerIcon;
    },
    [sizeIcon]
  );

  const changeOpen = useCallback(
    (cod: CodAerodromoType) => {
      dispatch(addCod(cod));
      setOpen(true);
      dispatch(menuToggle("close"));
    },
    [dispatch]
  );

  const changeClose = useCallback(() => {
    setOpen(false);
    //dispatch(menuToggle('open'));
  }, []);

  const StatusMap = useMemo(() => {
    return (
      data?.status &&
      (data.data?.map((coor, key) => {
        const position: LatLngExpression = [coor[2] as any, coor[3] as any];
        return (
          <Marker
            key={key}
            position={position}
            icon={iconColor(coor[4])}
            eventHandlers={{
              click: () => {
                coor[4] !== "cinza" ? changeOpen(coor[0]) : false;
              },
            }}
          >
            <Tooltip>
              <Typography variant={"subtitle2"}>
                {coor[0]} - {coor[1]}
              </Typography>
              <Typography variant={"body2"}>{nl2br(coor[5])}</Typography>
            </Tooltip>
          </Marker>
        );
      }) as any)
    );
  }, [data, changeOpen, iconColor]);

  const SidebarInfo = useMemo(() => {
    return (
      open &&
      ((
        <ControlDiv position="bottomleft">
          <Sidebar
            anchor="bottom"
            open={open}
            icon="down"
            onClose={changeClose}
          >
            <Informacao onClose={changeClose} />
          </Sidebar>
        </ControlDiv>
      ) as any)
    );
  }, [open, changeClose]);

  if (checkedLayer || active) {
    return (
      <React.Fragment>
        <ControlDiv position={"topright"}>
          <div {...props}>
            <LayerControlContainer
              title={"Aeródromos"}
              titleSuffix={`${dateUtc(new Date()).substring(0, 2)}Z`}
              isActive={control}
              toggle={setControl}
              loading={loading}
            >
              <AtualizacaoItem
                layer={layer}
                contador={layerRedux?.time}
                data={layerRedux?.lastUpdate}
              />
              <LineLegend columns={columnsLegend} data={dataLegend} />
              <LegendStatus />
            </LayerControlContainer>
          </div>
        </ControlDiv>
        {data?.data !== undefined && data.data.length > 0 && (
          <>
            {StatusMap}
            {SidebarInfo}
          </>
        )}
      </React.Fragment>
    );
  } else {
    return null;
  }
};
