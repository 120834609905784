import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/";
import { useDispatch, useSelector } from "react-redux";
import aerodromosRepositories, { IInfo } from "../../repositories/Aeroportos";
import mensagensRepositories, {
  IMeteograma,
  MeteogramaInitInitial,
} from "../../repositories/Mensagens";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Box, IconButton } from "@material-ui/core";
import { Markup } from "interweave";
import LineChart from "../../components/Charts/LineChart";
import BarChart from "../../components/Charts/BarChart";
import { isBrowser, isMobile } from "react-device-detect";
import { setValidades } from "../../store/Aerodromo/actionCreatores";
import AisWeb from "../../assets/images/logo-aisweb.png";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(
  createStyles({
    root: {
      width: "100%",
      maxHeight: "275px",
      minHeight: "275px",
      padding: "0px",
      margin: "0px",
      cursor: "default",
    },
    title: {
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "uppercase",
      margin: "0px",
    },
    texto: {
      fontSize: "12px",
      margin: "0px",
    },
    linha: {
      // direction: "row",
      justify: "center",
      alignItems: "top",
    },
    quebra: {
      position: "relative",
      whiteSpace: "pre-line",
      wordWrap: "break-word",
      fontSize: "12px",
    },
    grafico: {
      margin: "0px",
      height: "200px",
      width: "100%",
    },
    listDataTemperatura: {
      backgroundColor: "#E8EDF0",
      margin: 0,
      padding: 0,
      listStyle: "none",
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      height: `100%`,
    },
    topInfoL: {
      float: "left",
      position: "absolute",
      top: "0",
    },
    topInfoR: {
      float: "right",
      position: "relative",
      top: "0",
      zIndex: 50,
    },
  })
);

interface InformacaoProps {
  onClose: () => void;
}

export const Informacao = (props: InformacaoProps) => {
  const { onClose } = props;
  const classes = useStyles();
  const subscribed = useRef(true);

  /* State */
  const [info, setInfo] = useState<IInfo>();
  const [meteograma, setMeteograma] = useState<IMeteograma>(
    MeteogramaInitInitial
  );

  /* Redux */
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme);
  const aerodromos = useSelector((state: RootState) => state.aerodromo);

  const getInfo = useCallback(async () => {
    try {
      const { data } = await aerodromosRepositories.info<IInfo>(
        aerodromos.cod as any
      );
      if (subscribed.current) {
        setInfo(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [aerodromos.cod]);

  const getMeteograma = useCallback(async () => {
    try {
      const { data } = await mensagensRepositories.meteograma<IMeteograma>(
        aerodromos.cod as any,
        aerodromos.gridHours as any
      );
      if (subscribed.current) {
        setMeteograma(data);
        dispatch(
          setValidades({
            horas_futuro: data.data.horas_futuro,
            horas_futuro_max: data.data.horas_futuro_max,
            horas_passado: data.data.horas_passado,
            validade_total: data.data.validade_total,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [aerodromos.cod, aerodromos.gridHours, dispatch]);

  useEffect(() => {
    subscribed.current = true;
    getInfo();
    getMeteograma();
    return () => {
      subscribed.current = false;
    };
  }, [aerodromos.cod, aerodromos.gridHours, getInfo, getMeteograma]);

  const velocidade = {
    label: "Velocidade",
    color: "blue",
    data: meteograma.data?.vnt_vel,
    id: "LegendLeft",
  };
  const rajada = {
    label: "Rajada",
    color: "red",
    data: meteograma.data?.vnt_rajada,
    id: "LegendLeft",
  };
  const direcao = {
    label: "Direção",
    color: "green",
    data: meteograma.data?.vnt_dir,
    id: "LegendRight",
  };
  const vento = {
    label: "Vento Variando",
    color: "orange",
    data: meteograma.data?.vnt_vrb,
    id: "LegendRight",
  };
  const visibilidade = {
    label: "Visibilidade",
    color: "blue",
    data: meteograma.data?.vis,
    id: "LegendLeft",
  };
  const visibilidade_setor = {
    label: "Visibilidade Setor",
    color: "red",
    data: meteograma.data?.vis_setor,
    id: "LegendLeft",
  };
  const direcao_setor = {
    label: "Direção Setor",
    color: "green",
    data: meteograma.data?.vis_setor_dir,
    id: "LegendRight",
  };
  const teto = {
    label: "Teto",
    color: "red",
    data: meteograma.data?.teto,
    id: "LegendLeft",
  };
  const cobertura = {
    label: "Cobertura",
    color: "blue",
    data: meteograma.data?.cobertura,
    id: "LegendRight",
  };
  const tt = {
    label: "Temperatura",
    color: "blue",
    data: meteograma.data?.tt,
    id: "LegendLeft",
  };
  const po = {
    label: "Ponto de Orvalho",
    color: "green",
    data: meteograma.data?.po,
    id: "LegendLeft",
  };
  const ur = {
    label: "Umidade Relativa",
    color: "yellow",
    data: meteograma.data?.ur,
    id: "LegendRight",
  };
  const qnh = {
    label: "Pressão",
    color: "red",
    data: meteograma.data?.qnh,
    id: "LegendRight",
  };
  const nevoeiro = {
    label: "Nevoeiro",
    color: "green",
    data: meteograma.data?.nevoeiro,
    id: "LegendLeft",
    stack: "S0",
  };
  const nevoa_umida = {
    label: "Névoa Úmida",
    color: "green",
    data: meteograma.data?.nevoa_umida,
    id: "LegendLeft",
    stack: "S0",
  };
  const chuvisco = {
    label: "Chuvisco",
    color: "blue",
    data: meteograma.data?.chuvisco,
    id: "LegendLeft",
    stack: "S0",
  };
  const chuva = {
    label: "Chuva",
    color: "blue",
    data: meteograma.data?.chuva,
    id: "LegendLeft",
    stack: "S0",
  };
  const pancada = {
    label: "Pancada",
    color: "blue",
    data: meteograma.data?.pancada,
    id: "LegendLeft",
    stack: "S0",
  };
  const granizo = {
    label: "Granizo",
    color: "blue",
    data: meteograma.data?.granizo,
    id: "LegendLeft",
    stack: "S0",
  };
  const neve = {
    label: "Neve",
    color: "blue",
    data: meteograma.data?.neve,
    id: "LegendLeft",
    stack: "S0",
  };
  const trovoada = {
    label: "Trovoada",
    color: "red",
    data: meteograma.data?.trovoada,
    id: "LegendLeft",
    stack: "S0",
  };
  const fumaca = {
    label: "Fumaça",
    color: "brow",
    data: meteograma.data?.fumaca,
    id: "LegendLeft",
    stack: "S0",
  };
  const nevoa_seca = {
    label: "Névoa Seca",
    color: "brow",
    data: meteograma.data?.nevoa_seca,
    id: "LegendLeft",
    stack: "S0",
  };

  return (
    <Box className={classes.root} style={{ color: theme.fontColor }}>
      <Grid container spacing={0} className={classes.linha}>
        <Grid
          item
          xs={12}
          lg={3}
          md={3}
          style={{ borderRight: "1px solid black", padding: 0 }}
        >
          <Grid container spacing={0} className={classes.linha}>
            <Grid
              item
              xs={9}
              style={{
                padding: "12px",
                paddingRight: "12px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <Typography className={classes.title}>
                {info?.data.nome}
              </Typography>
              <Typography
                className={classes.texto}
                style={{ paddingTop: "5px" }}
              >
                {info?.data.localidade} - {info?.data.cidade}
                <br />
                {info?.data.localizacao}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ul className={classes.listDataTemperatura}>
                <li
                  style={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    height: "33%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <b>{info?.data?.data?.split(" ")[0]}</b>
                </li>
                <li
                  style={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    height: "34%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "18px" }}>
                    {info?.data.temperatura}
                  </span>
                  <img
                    src={info?.data.tempoImagem as any}
                    style={{
                      width: "26px",
                      height: "26px",
                      top: "-20px",
                      background: "transparent",
                      boxShadow: "none",
                    }}
                  />
                </li>
                <li
                  style={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    height: "33%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <b>{info?.data.data.split(" ")[1]}</b>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.linha}>
            <Grid item xs={12}>
              <div style={{ position: "relative" }}>
                <div className={classes.topInfoL} style={{ width: "100%" }}>
                  <Tabs>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "8px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingRight: "8px",
                        backgroundColor: "#E8EDF0",
                      }}
                    >
                      {/* <div>
                                               <b>{switchText}</b>
                                               <div style={{display: 'flex', justifyContent: 'center'}}>
                                                   <AntSwitch checked={checked} onChange={toggleChecked} />
                                               </div>
                                            </div> */}
                      <TabList style={{ fontSize: "12px" }}>
                        METAR/TAF:&nbsp;
                        <Tab>Codificado</Tab>
                        <Tab>Decodificado</Tab>
                      </TabList>
                      {!isMobile && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "gray",
                            textDecoration: "none",
                            textTransform: "uppercase",
                            margin: "0px",
                          }}
                        >
                          <a
                            href={`https://aisweb.decea.mil.br/?i=aerodromos&codigo=${info?.data.localidade}`}
                            rel="noreferrer"
                            target="_blank"
                            style={{ textDecoration: "none", color: "gray" }}
                          >
                            <img
                              src={AisWeb}
                              alt="Logo AISWEB"
                              style={{ width: "50px" }}
                            />
                            <br />
                            ROTAER
                          </a>
                        </div>
                      )}
                      {isMobile && (
                        <IconButton
                          style={{ color: theme.fontColor }}
                          onClick={onClose}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </div>
                    <TabPanel className={classes.quebra}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "5px",
                          paddingLeft: "16px",
                          paddingBottom: "0px",
                          overflow: "auto",
                          height: "145px",
                        }}
                        className={classes.topInfoL}
                      >
                        <div>
                          <span style={{ marginRight: "10px" }}>
                            <div
                              style={{
                                width: "100%",
                                marginBottom: "5px",
                                backgroundColor: "#E8EDF0",
                              }}
                            >
                              <b>METAR:</b>
                            </div>
                            {info?.data.metar}
                          </span>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "5px",
                            marginBottom: "5px",
                            backgroundColor: "#E8EDF0",
                          }}
                        >
                          <b>TAF:</b>
                        </div>
                        {info?.data.taf}
                      </div>
                      {/*<Box style={{ width:'100%', paddingTop: '5px', paddingLeft: '16px', paddingBottom: '2px' }} className={classes.topInfoL}>*/}
                      {/*    <b>Metar</b><br/>*/}
                      {/*    {info?.data.metar}<br />*/}
                      {/*    <b>Taf:</b><br />*/}
                      {/*    {info?.data.taf}*/}
                      {/*</Box>*/}
                    </TabPanel>
                    <TabPanel className={classes.quebra}>
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "5px",
                          paddingLeft: "16px",
                          paddingBottom: "0px",
                          overflow: "auto",
                          height: "145px",
                        }}
                        className={classes.topInfoL}
                      >
                        <div
                          style={{
                            width: "100%",
                            marginBottom: "5px",
                            backgroundColor: "#E8EDF0",
                          }}
                        >
                          <b>METAR:</b>
                        </div>
                        <div>
                          <span style={{ marginRight: "10px" }}>
                            <b>Céu:</b> {info?.data.ceu}
                          </span>
                          <b>UR:</b> {info?.data.ur}
                        </div>
                        <b>Visibilidade:</b> {info?.data.visibilidade}
                        <br />
                        <b>Teto:</b> {info?.data.teto}
                        <br />
                        <b>Condições do Tempo:</b> {info?.data.condicoes_tempo}
                        <br />
                        <b>Vento:</b> {info?.data.vento}
                        <div
                          style={{
                            width: "100%",
                            marginTop: "5px",
                            marginBottom: "5px",
                            backgroundColor: "#E8EDF0",
                          }}
                        >
                          <b>TAF:</b>
                        </div>
                        {<Markup content={info?.data.tafDec} />}
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {isBrowser && (
          <Grid item xs={9}>
            <Tabs defaultIndex={4}>
              <div style={{ margin: "5px 0 5px 26px" }}>
                <TabList value={"Visibilidade"}>
                  <Tab>Vento</Tab>
                  <Tab>Visibilidade</Tab>
                  <Tab>Tempo Presente</Tab>
                  <Tab>Nebulosidade</Tab>
                  <Tab>Temperatura / Pressão</Tab>
                  <Tab>Temperatura / UR</Tab>
                </TabList>
              </div>
              <TabPanel tabIndex={0}>
                <div className={classes.grafico}>
                  <LineChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[velocidade, rajada, direcao, vento]}
                    titleLegendLeft="Nó(KT)"
                    titleLegendRight="Direção em Grau"
                    ticksRightMin={0}
                    legendPosition="bottom"
                    ticksRightMax={360}
                    ticksRightStep={45}
                    colorGrid={theme.gridColor}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
              <TabPanel tabIndex={1}>
                <div className={classes.grafico}>
                  <LineChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[visibilidade, visibilidade_setor, direcao_setor]}
                    titleLegendLeft="Metros (m)"
                    titleLegendRight="Direção em Grau"
                    ticksLeftMin={0}
                    ticksLeftMax={10000}
                    ticksLeftStep={1000}
                    ticksRightMin={0}
                    ticksRightMax={360}
                    ticksRightStep={45}
                    colorGrid={theme.gridColor}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
              <TabPanel tabIndex={2}>
                <div className={classes.grafico}>
                  <BarChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[
                      nevoeiro,
                      nevoa_umida,
                      chuvisco,
                      chuva,
                      pancada,
                      granizo,
                      neve,
                      trovoada,
                      fumaca,
                      nevoa_seca,
                    ]}
                    titleLegendLeft="Ocorrência"
                    ticksLeftMin={0}
                    ticksLeftMax={10}
                    ticksLeftStep={1}
                    callbacks={true}
                    colorGrid={theme.gridColor}
                    sobreposto={true}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
              <TabPanel tabIndex={3}>
                <div className={classes.grafico}>
                  <LineChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[teto, cobertura]}
                    titleLegendLeft="Pé (ft)"
                    titleLegendRight="Oitavos"
                    ticksLeftMin={0}
                    ticksLeftMax={10000}
                    ticksLeftStep={1000}
                    ticksRightMin={0}
                    ticksRightMax={8}
                    ticksRightStep={1}
                    colorGrid={theme.gridColor}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
              <TabPanel tabIndex={4}>
                <div className={classes.grafico}>
                  <LineChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[po, tt, qnh]}
                    titleLegendLeft="Grau Celsius (ºC)"
                    titleLegendRight="hPa"
                    colorGrid={theme.gridColor}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
              <TabPanel tabIndex={5}>
                <div className={classes.grafico}>
                  <LineChart
                    labels={meteograma.data?.validade_inicial_horizontal}
                    dados={[po, tt, ur]}
                    titleLegendLeft="Grau Celsius (ºC)"
                    titleLegendRight="%"
                    colorGrid={theme.gridColor}
                    horasPassadas={meteograma.data.horas_passado}
                  />
                </div>
              </TabPanel>
            </Tabs>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
