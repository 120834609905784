import React from "react";
import "./styles.css";
import { menuToggle } from "../../../store/Menu/actionCreatores";
import { useDispatch } from "react-redux";
import IconRedemet from "../../../assets/images/icon_Redemet_192px-8.png";
import { Box, Button } from "@material-ui/core";

const SidebarTrigger = (props) => {
  const dispatch = useDispatch();
  return (
    <Box {...props} style={{ padding: "10px" }}>
      <Button onClick={() => dispatch(menuToggle("open"))}>
        <img src={IconRedemet} width="64px" />
      </Button>
    </Box>
  );
};

export default SidebarTrigger;
