import React from "react";
import {
  MapContainer,
  WMSTileLayer,
  ZoomControl,
  AttributionControl,
} from "react-leaflet";
import { CRS, LatLngTuple } from "leaflet";
import Menu from "./components/Menu";
import { VERSAO } from "../../config";
import Position from "../../components/Leaflet/Position";

const Map = () => {
  const center: LatLngTuple = [-15.7801, -55.0];
  const zoom = 4;
  const minZoom = 2;

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      minZoom={minZoom}
      zoomControl={false}
      attributionControl={false}
      crs={CRS.EPSG4326}
    >
      <AttributionControl position="topright" prefix={`REDEMET ${VERSAO}`} />
      <ZoomControl position="bottomright" />
      {/*<LeafletDistance/>*/}
      <WMSTileLayer
        //layers="TOPO-OSM-WMS"
        layers="OSM-WMS"
        //url="http://ows.mundialis.de/services/service?"
        url="https://ows.terrestris.de/osm/service?"
        // url="https://ows.terrestris.de/osm-gray/service?"
      />
      <Position />
      <Menu />
    </MapContainer>
  );
};

export default Map;
