import React, { FC, useCallback, useMemo, useState } from "react";
import { IStatus } from "../../../repositories/Aeroportos";
import { icon } from "leaflet";
import { Marker, Tooltip, useMapEvents } from "react-leaflet";
import { LatLngExpression } from "leaflet";

import Green from "../../../assets/aerodromos/green.svg";
import GreenW from "../../../assets/aerodromos/greenw.svg";
import Red from "../../../assets/aerodromos/red.svg";
import RedW from "../../../assets/aerodromos/redw.svg";
import Yellow from "../../../assets/aerodromos/yellow.svg";
import YellowW from "../../../assets/aerodromos/yelloww.svg";
import Cinza from "../../../assets/aerodromos/cinza.svg";

import { Typography } from "@material-ui/core";
import nl2br from "react-nl2br";

interface StatusProps extends React.ComponentProps<any> {
  data: IStatus;
}

export const Aerodromos: FC<StatusProps> = ({ data }) => {
  const [sizeIcon, setSizeIcon] = useState<L.PointExpression>([12, 12]);

  useMapEvents({
    zoom(e) {
      const size = e.sourceTarget._zoom * 4;
      setSizeIcon([size, size]);
    },
  });

  const iconColor = useCallback(
    (color: string) => {
      let iconMarkup;
      switch (color) {
        case "g":
          iconMarkup = Green;
          break;
        case "gw":
          iconMarkup = GreenW;
          break;
        case "r":
          iconMarkup = Red;
          break;
        case "rw":
          iconMarkup = RedW;
          break;
        case "y":
          iconMarkup = Yellow;
          break;
        case "yw":
          iconMarkup = YellowW;
          break;
        case "cinza":
          iconMarkup = Cinza;
          break;
      }

      const customMarkerIcon = icon({
        iconUrl: iconMarkup,
        iconSize: sizeIcon,
      });
      return customMarkerIcon;
    },
    [sizeIcon]
  );

  const StatusMap = useMemo(() => {
    return (
      data?.status &&
      (data.data?.map((coor, key) => {
        const position: LatLngExpression = [coor[2] as any, coor[3] as any];
        return (
          <Marker key={key} position={position} icon={iconColor(coor[4])}>
            <Tooltip>
              <Typography variant={"subtitle2"}>
                {coor[0]} - {coor[1]}
              </Typography>
              <Typography variant={"body2"}>{nl2br(coor[5])}</Typography>
            </Tooltip>
          </Marker>
        );
      }) as any)
    );
  }, [data, iconColor]);

  return (
    <React.Fragment>
      {data?.data !== undefined && data.data.length > 0 && <>{StatusMap}</>}
    </React.Fragment>
  );
};
