import React from "react";
import { WMSTileLayer, WMSTileLayerProps } from "react-leaflet";

const WmsTiles = (props: WMSTileLayerProps) => {
  return (
    <WMSTileLayer
      url={props.url}
      layers={props.layers}
      format="image/png"
      transparent
    />
  );
};

export default WmsTiles;
