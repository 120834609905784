import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

export default function TermoUso() {
  return (
    <React.Fragment>
      <DialogContent>
        <Container maxWidth="lg">
          <Typography variant="h6">
            {
              "São condições de acesso que devem ser aplicadas ao uso dos dados e produtos disponíveis no web site da REDEMET do Comando da Aeronáutica."
            }
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Os direitos autorais do conteúdo gráfico, textos, imagens e multimídias são protegidos por legislação nacional e, a nível internacional, pelo que prevê a Convenção de Berna." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="O Logotipo do DECEA e/ou emblema da REDEMET não podem ser removidos de nenhuma página ou ambiente gráfico em que figurem, nem poderão ser interligados em páginas ou elementos gráficos pertencentes a outros sites." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Não estão autorizados Links cujo resultado seja a exibição de uma página ou imagem da REDEMET emoldurada em outros sites." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Os Links estão autorizados e devem estar apontados, inequivocadamente, como Links de acesso a REDEMET, exclusivamente à página principal." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Todos os acessos a este Servidor são registrados e, em caso de invasão danosa a este site ou a qualquer parte do seu conteúdo, estas informações serão utilizadas para identificar o autor." />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="As imagens de satélites contidas neste Servidor pertencem à Administração Oceânica e Atmosférica Nacional dos Estados Unidos da América (NOAA) e são fornecidas pelo INMET. As condições de uso sobre as imagens podem ser encontradas no site da NOAA (www.noaa.gov)." />
            </ListItem>
          </List>
        </Container>
      </DialogContent>
    </React.Fragment>
  );
}
