export const SIDEBAR_THEME = "@theme/THEME_SIDEBAR";
export const CHARTS_FONTCOLOR = "@statusColor/CHARTS_FONTCOLOR";
export const CHARTS_GRIDCOLOR = "@statusColor/CHARTS_GRIDCOLOR";
export const CHARTS_LINESCOLOR = "@statusColor/CHARTS_LINESCOLOR";
export const TEMA_NAME = "@statusColor/TEMA_NAME";
export const CHARTS_HOURS = "@statusColor/CHARTS_HOURS";

interface ILinesColor {
  red: string;
  orange: string;
  yellow: string;
  green: string;
  blue: string;
  grey: string;
  brow: string;
}

export interface ITheme {
  bgColor?: string;
  fontColor?: string;
  gridColor?: string;
  linesColor?: ILinesColor;
  temaName?: string;
  gridHours?: number[];
}

export type ThemeActionTypes = {
  type: string;
  payload: ITheme;
};

//type DispatchType = (args: StatusColorAction) => StatusColorAction
