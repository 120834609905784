import { useState } from "react";
import { useMapEvents } from "react-leaflet";
import { useDispatch } from "react-redux";
import { removeLayer } from "../store/Layers/actionCreatores";

type Props = {
  layer: string;
};

const useLayerChecked = (props: Props) => {
  const dispatch = useDispatch();
  const [checkedLayer, setCheckedLayer] = useState<boolean>(false);

  useMapEvents({
    layeradd: (event) => {
      if (
        (event.layer as any).options.name !== undefined &&
        (event.layer as any).options.name === props.layer
      ) {
        setCheckedLayer(true);
      }
    },
    layerremove: (event) => {
      if (
        (event.layer as any).options.name !== undefined &&
        (event.layer as any).options.name === props.layer
      ) {
        setCheckedLayer(false);
        dispatch(removeLayer(props.layer));
      }
    },
  });

  return { checkedLayer };
};

export default useLayerChecked;
