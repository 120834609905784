import { AxiosInstance, AxiosResponse } from "axios";
import Api from "../config/Api";

interface Iresource {
  readonly status: boolean;
  readonly message: string;
}

export interface IStatus extends Iresource {
  readonly data: string[];
}

export interface IInfo extends Iresource {
  readonly data: {
    localidade: string;
    nome: string;
    cidade: string;
    lon: string;
    lat: string;
    localizacao: string;
    metar: string;
    data: string;
    temperatura: string;
    ur: string;
    visibilidade: string;
    teto: string;
    ceu: string;
    condicoes_tempo: string;
    tempoImagem: string;
    vento: string;
    aviso: string;
    taf: string;
    tafDec: string;
  };
}

export class Aerodromos {
  constructor(private http: AxiosInstance, protected resource: string) {}

  status<T = any>(paises: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(
      `${this.resource}/status?pais=${paises}&emcar=true`
    );
  }

  info<T = any>(cod: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(
      `${this.resource}/info?localidade=${cod}&metar='sim'&taf='sim'&aviso='sim'`
    );
  }
}

const aerodromosRepositories = new Aerodromos(Api, "aerodromos");
export default aerodromosRepositories;
