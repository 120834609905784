import {
  ADD_LAYER,
  LayerActionTimerTypes,
  LayerActionUpdateTypes,
  Layers,
  LayersActionDeleteTypes,
  LayersActionTypes,
  REMOVE_LAYER,
  UPDATE_LAYER,
  UPDATE_TIME,
} from "./types";

export const addLayer = (layer: Layers): LayersActionTypes => {
  return {
    type: ADD_LAYER,
    payload: layer,
  };
};

export const removeLayer = (layer: string): LayersActionDeleteTypes => {
  return {
    type: REMOVE_LAYER,
    payload: layer,
  };
};

export const updateLayer = (
  layer: string,
  update: boolean
): LayerActionUpdateTypes => {
  return {
    type: UPDATE_LAYER,
    payload: {
      layer,
      update,
    },
  };
};

export const updateTime = (
  layer: string,
  time: string
): LayerActionTimerTypes => {
  return {
    type: UPDATE_TIME,
    payload: {
      layer,
      time,
    },
  };
};
