import React from "react";
import { Box } from "@material-ui/core";
import MCircularProgress from "./Material/MCircularProgress";

const Loader = () => {
  return (
    <Box
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100%",
        marginTop: "15px",
      }}
    >
      <MCircularProgress />
    </Box>
  );
};

export default Loader;
