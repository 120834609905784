export const ADD_COD = "@aerodromo/ADD_COD";
export const SET_GRID_HOUR = "@aerodromo/SET_GRID_HOUR";
export const SET_VALIDADES = "@aerodromos/SET_VALIDADES";

export type CodAerodromoType = string;
export type HourAerodromoType = number[];
export type ValidadesAerodromoType = {
  validade_total: number;
  horas_passado: number;
  horas_futuro: number;
  horas_futuro_max: number;
};

export interface IAerodromo {
  cod?: CodAerodromoType;
  gridHours?: HourAerodromoType;
  validades: ValidadesAerodromoType;
}

export type AerodromoActionCodTypes = {
  type: string;
  payload: CodAerodromoType;
};

export type AerodromoActionHourTypes = {
  type: string;
  payload: HourAerodromoType;
};

export type AerodromoActionValidadesTypes = {
  type: string;
  payload: ValidadesAerodromoType;
};

export type AerodromoActionTypes =
  | AerodromoActionCodTypes
  | AerodromoActionHourTypes
  | AerodromoActionValidadesTypes;
