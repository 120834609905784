import React, {useEffect, useState} from "react";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {ISigmet} from "../../repositories/Mensagens";
import {useDispatch, useSelector} from "react-redux";
import {checkSigmet} from "../../store/Sigmet/actionCreatores";
import {RootState} from "../../store";

interface Props {

	dados: ISigmet[];
}

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			verticalAlign: "middle",
		},
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
			fontSize: 12,
		},
		sizeSmall: {
			padding: "2px 5px 2px 5px",
		},
		body: {
			fontSize: 11,
		},
	})
)(TableCell);


const columnsLegend = [
	{
		"fenomeno": 'VA CLD',
		"descricao": 'Cinzas Vulcânicas',
		"cor": "FF7F50"
	},
	{
		"fenomeno": 'RDOACT CLD',
		"descricao": 'Nuvem Radioativa',
		"cor": "CEECF5"
	},
	{
		"fenomeno": 'ICE',
		"descricao": 'Gelo',
		"cor": "00BFFF"
	},
	{
		"fenomeno": 'TC',
		"descricao": 'Ciclone',
		"cor": "000000"
	},
	{
		"fenomeno": 'TS',
		"descricao": 'Trovoada',
		"cor": "FF0000"
	},
	{
		"fenomeno": 'TURB',
		"descricao": 'Turbulência',
		"cor": "FFFF00"
	},
	{
		"fenomeno": 'VA',
		"descricao": 'Vulcão',
		"cor": "5C2E00"
	}
];
const LineLegendSigmet = (props: Props) => {
	const dispatch = useDispatch();
	const sigmetChecks = useSelector(
		(state: RootState) => state.sigmet
	);

	const updateCheckFenomeno = (fenomeno: string) => {
		if(sigmetChecks.includes(fenomeno)){
			dispatch(checkSigmet(sigmetChecks.filter((item) => item !== fenomeno)));
		}else{
			dispatch(checkSigmet([...sigmetChecks, fenomeno]));
		}
	}
	const countFenomeno = (fenomeno: string) => {
		let count = 0;

		props.dados.forEach((row) => {
			if (row.fenomeno === fenomeno) {
				count ++;
			}
		})
		return count
	}

  	return (
		<>
			<TableContainer component={Paper}>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>

							<StyledTableCell> </StyledTableCell>
							<StyledTableCell>Fenômeno</StyledTableCell>
							<StyledTableCell>Qtd</StyledTableCell>
							<StyledTableCell>Descrição</StyledTableCell>
							<StyledTableCell>Cor</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{columnsLegend.map((row: any, index) => {

							const style = {
								background:
									"linear-gradient(to right, #" +
									`${row.cor}` +
									" 90%, white 10%)",
							};


							return (
								<TableRow key={index}>
									<StyledTableCell>
										<input
											type="checkbox"
											name="option1"
											checked={sigmetChecks.includes(row.fenomeno)}
											onChange={() => updateCheckFenomeno(row.fenomeno)}
										/>
									</StyledTableCell>
									<StyledTableCell>
										{row.fenomeno}
									</StyledTableCell>
									<StyledTableCell>
										{countFenomeno(row.fenomeno)}
									</StyledTableCell>
									<StyledTableCell>
										{row.descricao}
									</StyledTableCell>
									<StyledTableCell style={style}>
									</StyledTableCell>


									{/*{rows.map((row, i) => {*/}


									{/*	return (*/}
									{/*		<StyledTableCell key={i} style={style}>*/}
									{/*			{isImage(row) ? isImage(row) : !isHexColor(row) && row}*/}
									{/*		</StyledTableCell>*/}
									{/*	);*/}
									{/*})}*/}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default React.memo(LineLegendSigmet);
