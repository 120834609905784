import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      verticalAlign: "middle",
    },
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 12,
    },
    sizeSmall: {
      padding: "0px 5px 0px 5px",
    },
    body: {
      fontSize: 11,
    },
  })
)(TableCell);

export default function LegendStatus() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={2}>
                Quanto à hora da informação do METAR
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Informação da hora atual</StyledTableCell>
              <StyledTableCell>
                <img src={"assets/aerodromos/currentcolor.svg"} width={50} />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                Informações de até 3 horas passadas
              </StyledTableCell>
              <StyledTableCell>
                <img src={"assets/aerodromos/pastcolor.svg"} width={50} />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                Sem informações de até 3 horas passadas
              </StyledTableCell>
              <StyledTableCell>
                <img src={"assets/aerodromos/cinza.svg"} width={15} />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
