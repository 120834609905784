import React, { useCallback, useEffect, useState } from "react";
import Control from "../../../components/AnimationImageOverlay/components/Control";
import ImageOverlay from "../../../components/AnimationImageOverlay/components/ImageOverlay";
import useControlAnimation from "../../../hooks/useControlAnimation";
import ControlDiv from "../../../components/Leaflet/ControlDiv";
import { IRadarDados } from "../../../repositories/Produtos";
import { Circle, Popup } from "react-leaflet";
import Typography from "@material-ui/core/Typography";

type data = {
  path: string;
};

export type parameters = {
  label: string;
  param: any;
  legends?: React.ReactNode;
};
export interface IImage {
  latLon: {
    id: number;
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
  };
  tempo: string[];
  imagens: data[];
}

export interface IAnimationImageOverlay extends IImage {
  multi?: true;
  layer: string;
  checkedLayer: boolean;
  parameterUrlDefault: string;
  onChangeData?: (param: any) => void;
  parameters?: parameters[];
}

const Animation = (props: IAnimationImageOverlay) => {
  const {
    checkedLayer,
    layer,
    imagens,
    latLon = {
      id: 0,
      lon_min: 0,
      lon_max: 0,
      lat_min: 0,
      lat_max: 0,
    },
    multi = false,
    onChangeData,
    tempo: propsTempo,
    parameters,
    parameterUrlDefault,
  } = props;

  const [downloadSuccess, setDownloadSucess] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastTimeLayer, setLastTimeLayer] = useState<string | undefined>("");

  const {
    opacity,
    velocidade,
    index,
    pause,
    play,
    running,
    changeIndex,
    changeOpacity,
    tempoIndex,
    changeVelocidade,
  } = useControlAnimation({
    totalImagens: downloadSuccess,
    tempo: propsTempo,
  });

  useEffect(() => {
    setLastTimeLayer(`${propsTempo[propsTempo.length - 1]} UTC`);
  }, [propsTempo]);

  useEffect(() => {
    if (!checkedLayer) {
      pause();
    }
  }, [checkedLayer, pause]);

  const changeDownloadSucess = useCallback((downloads: number): void => {
    if (downloads > 0) {
      setDownloadSucess(downloads);
      setLoading(false);
    }
  }, []);

  const changeParameters = useCallback(
    (parameters: any) => {
      if (onChangeData !== undefined) {
        pause();
        setLoading(true);
        onChangeData(parameters);
      }
    },
    [onChangeData, pause]
  );

  return (
    <>
      <ImageOverlay
        latLon={latLon}
        tempo={propsTempo}
        imageIndex={index}
        opacity={opacity}
        imagens={imagens}
        onDownloadSucess={changeDownloadSucess}
        multi={multi}
      />
      {checkedLayer && (
        <>
          <ControlDiv position={"topright"}>
            <Control
              layer={layer}
              lastTimeLayer={lastTimeLayer}
              totalImages={downloadSuccess}
              running={running}
              play={play}
              pause={pause}
              tempo={tempoIndex}
              velocidade={velocidade}
              onIndex={changeIndex}
              indexImage={index}
              valueOpacity={opacity}
              onOpacity={changeOpacity}
              onVelocidade={changeVelocidade}
              parametersUrl={parameters}
              onParameters={changeParameters}
              parametersUrlDefault={parameterUrlDefault}
              loading={loading}
            />
          </ControlDiv>
          {imagens.length > 0 &&
            (imagens[index] as any).map((row: IRadarDados, index: number) => {
              const color = row.path === null ? "#721c24a3" : "#999";
              return (
                <Circle
                  key={index}
                  stroke={false}
                  color={color}
                  center={[row.lat_center as number, row.lon_center as number]}
                  radius={row.raio * 1000}
                >
                  <Popup>
                    <Typography variant="subtitle2" gutterBottom>
                      {row.nome}
                    </Typography>
                    <Typography variant="overline" display="block" gutterBottom>
                      {row.data ? row.data : "Não disponível"}
                    </Typography>
                  </Popup>
                </Circle>
              );
            })}
        </>
      )}
    </>
  );
};

export default Animation;
