import * as React from "react";
import { Circle, FeatureGroup } from "react-leaflet";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import produtosRepositories, { IStscDados } from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import LayerControlContainer from "../../components/LayerControl/LayerControlContainer";
import { dateUtc } from "../../utils/dates";
import ControlDiv from "../../components/Leaflet/ControlDiv";
import useLayerChecked from "../../hooks/useLayerChecked";
import LineLegend from "../../components/LayerControl/LineLegend";
import { TSC } from "../../config";
import TimelineControl from "../../components/LayerControl/TimelineControl";
import { Box, FormControlLabel, Switch } from "@material-ui/core";
import useControlAnimation from "../../hooks/useControlAnimation";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import AtualizacaoItem from "../../components/LayerControl/AtualizacaoItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { subMinutes } from "date-fns";

interface TscType {
  active?: boolean;
}

const columnsLegend = ["Tempo", "Cor"];
const dataLegend = [
  ["15 min até Agora", "ff0000"],
  ["30 min até 15 min", "ffff00"],
  ["45 min até 30 min", "008000"],
  ["60 min até 45 min", "0000ff"],
];
const DateNow = new Date();
const DateUTC = dateUtc(DateNow).substring(0, 5);

const layer = TSC;
const Tsc: FC<TscType> = ({ active = false }) => {
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });
  const actived = active || checkedLayer;
  const subscribed = useRef(true);
  const [data, setData] = useState<IStscDados[]>([]);
  const [control, setControl] = useState<boolean>(false);
  const [arrayOpacity, setArrayOpacity] = useState<Array<number>>([]);
  const [animacao, setAnimacao] = useState(false);
  const [tempo, setTempo] = useState<Array<string>>([]);
  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );
  const [loading, setLoading] = useState<boolean>(false);
	const [horaProduto, setHoraProduto] = useState<string>(dateUtc(new Date()).substring(0, 5));


  const toggleChecked = () => {
    setAnimacao((prev) => !prev);
  };

  const {
    index,
    pause,
    play,
    running,
    changeIndex,
    tempoIndex,
    changeVelocidade,
  } = useControlAnimation({
    totalImagens: data.length,
    tempo: tempo,
  });


  useEffect(() => {
    if (animacao === false) {
      if (running) {
        pause();
      }
    }
  }, [animacao, running, pause]);

  useEffect(() => {
    if (data.length > 0 && animacao) {
      subtrairTempo();
      const arrayOpacity = new Array(data.length).fill(0);
      arrayOpacity[data.length - 1] = 1;
      setArrayOpacity(arrayOpacity);
    }

    function subtrairTempo() {
      setTempo(data.map((row) => dateUtc(subMinutes(DateNow, row.min))));
    }
  }, [data, animacao]);

  const handlePlayPause = useCallback(() => {
    if (running) {
      pause();
    } else {
      play();
    }
  }, [pause, play, running]);

  useEffect(() => {
    if (animacao) {
      const newOpacity = arrayOpacity.fill(0);
      newOpacity[index] = 1;
      setArrayOpacity(newOpacity);
    }
  }, [index, animacao, arrayOpacity]);

  const getTsc = useCallback(async () => {
    setData([]);
    setLoading(true);
    try {
      const { data } = await produtosRepositories.stsc<IResponse<IStscDados[]>>(
        animacao ? 1 : 0
      );
      if (subscribed.current) {
				if(data.data[0].horario){
					setHoraProduto(data.data[0].horario.substring(11, 16))
				}
        setData(data.data.reverse());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [animacao]);

  useLayerUpdated({
    onLoad: getTsc,
    seconds: 300,
    checkedLayer: actived,
    layer,
  });

  useEffect(() => {
    if (actived) {
      subscribed.current = true;
      getTsc();
      return () => {
        subscribed.current = false;
      };
    }
  }, [checkedLayer, getTsc, animacao, actived]);

  const handleChangeSlider = useCallback(
    (event: any, newValue: number | number[]) => {
      changeIndex(newValue as number);
    },
    [changeIndex]
  );

  const circle = useMemo(() => {
    if (data.length > 0) {
      return data.map((row, i) => {
        return (
          <FeatureGroup
            key={i}
            pathOptions={{
              fillOpacity: animacao ? arrayOpacity[i] : 1,
            }}
          >
            {row.pontos.map((ponto: any, index) => (
              <Circle
                key={index}
                stroke={false}
                pathOptions={{
                  color: row.cor,
                }}
                center={[ponto.la, ponto.lo]}
                radius={10000}
              />
            ))}
          </FeatureGroup>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, animacao, index, arrayOpacity]);
  const animacaoRender = useMemo(() => {
    if (animacao) {
      if (!loading) {
        return (
          <TimelineControl
            timeValue={index}
            timeLabel={`${tempoIndex}Z`}
            step={1}
            min={0}
            maxTimeValue={data.length - 1}
            onVelocityChange={changeVelocidade}
            handlePlayPause={handlePlayPause}
            onTimelineChange={handleChangeSlider}
          />
        );
      }
    }
  }, [
    index,
    animacao,
    tempoIndex,
    data,
    loading,
    handlePlayPause,
    changeVelocidade,
    handleChangeSlider,
  ]);

  if (actived) {
    return (
      <>
        {circle}
        <ControlDiv position={"topright"}>
          <LayerControlContainer
            title={"TSC"}
            titleSuffix={`${horaProduto} UTC`}
            isActive={control}
            toggle={setControl}
            loading={loading}
          >
            <AtualizacaoItem
              layer={layer}
              contador={layerRedux?.time}
              data={layerRedux?.lastUpdate}
            />
            <Box style={{ padding: 10 }}>
              <FormControlLabel
                control={
                  <Switch
                    color={"primary"}
                    size="small"
                    checked={animacao}
                    onChange={toggleChecked}
                  />
                }
                label="Animar"
              />
            </Box>
            {animacaoRender}
            {!animacao && (
              <LineLegend columns={columnsLegend} data={dataLegend} />
            )}
          </LayerControlContainer>
        </ControlDiv>
      </>
    );
  } else {
    return null;
  }
};

export default Tsc;
