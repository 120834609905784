import { ITheme, SIDEBAR_THEME, ThemeActionTypes } from "./types";

const INITIAL_STATE: ITheme = {
  bgColor: "rgba(255,255,255,0.9)",
  fontColor: "#000000",
  gridColor: "#666666",
  linesColor: {
    red: "179, 25, 25",
    orange: "204, 102, 0",
    yellow: "242, 242, 13",
    green: "102, 204, 0",
    blue: "0, 102, 204",
    grey: "201, 203, 207",
    brow: "102, 51, 0",
  },

  temaName: "Escuro",
  gridHours: [12, 72],
};

const themeReducer = (
  state = INITIAL_STATE,
  action: ThemeActionTypes
): ITheme => {
  switch (action.type) {
    case SIDEBAR_THEME:
      return {
        ...state,
        ...action.payload,
      };
    // case actionTypes.STATUS_CHARTS_FONTCOLOR:
    //     return {
    //         ...state,
    //         fontColor: action.payload as any
    //     }
    // case actionTypes.STATUS_CHARTS_GRIDCOLOR:
    //     return {
    //         ...state,
    //         gridColor: action.payload as any
    //     }
    // case actionTypes.STATUS_CHARTS_LINESCOLOR:
    //     return {
    //         ...state,
    //         linesColor: action.payload as any
    //     }
    // case actionTypes.STATUS_TEMA_NAME:
    //     return {
    //         ...state,
    //         ...state,
    //         temaName: action.payload as any
    //     }
    // case actionTypes.STATUS_CHARTS_HOURS:
    //     return {
    //         ...state,
    //         gridHours: action.payload as any
    //     }
    default:
      return state;
  }
  return state;
};

export default themeReducer;
