import React, { FunctionComponent, ReactNode, useRef, useState } from "react";
import "./styles.css";
import { ReactComponent as ChevronUp } from "../../../assets/svg/icons/icon-chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../assets/svg/icons/icon-chevron-down.svg";

type props = {
  children: ReactNode;
  title?: string;
  name: string;
  icon?: ReactNode;
};

const SidebarCollapsibleChild: FunctionComponent<props> = ({
  children,
  icon,
  title,
  name,
}: props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={
        isOpen
          ? "redemet-mainsidebar-collapsible child is-open"
          : "redemet-mainsidebar-collapsible child"
      }
    >
      <div
        className="collapsible-titlebar"
        onClick={() => {
          // if(!isOpen) {
          //   contentWrapperRef.current?.style.setProperty('max-height', `${contentWrapperRef.current?.scrollHeight}px`);
          // } else {
          //   contentWrapperRef.current?.style.setProperty('max-height', `0`);
          // }
          setIsOpen(!isOpen);
        }}
      >
        {icon && <span className="icon-start">{icon}</span>}

        {
          <span className="title">
            <span className="item-wrapper" title={title}>
              {name}
            </span>
          </span>
        }

        <span className="icon-end">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>
      <div className="collapsible-content-wrapper" ref={contentWrapperRef}>
        <div className="collapsible-content">{children}</div>
      </div>
    </div>
  );
};

export default SidebarCollapsibleChild;
