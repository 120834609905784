import {
  ADD_COD,
  AerodromoActionTypes,
  CodAerodromoType,
  HourAerodromoType,
  IAerodromo,
  SET_GRID_HOUR,
  SET_VALIDADES,
  ValidadesAerodromoType,
} from "./types";

const INITIAL_STATE: IAerodromo = {
  cod: "",
  gridHours: [-24, 6],
  validades: {
    horas_futuro: 0,
    horas_futuro_max: 0,
    horas_passado: 0,
    validade_total: 0,
  },
};

const aerodromoReducer = (
  state = INITIAL_STATE,
  action: AerodromoActionTypes
): IAerodromo => {
  switch (action.type) {
    case ADD_COD:
      return {
        ...state,
        cod: action.payload as CodAerodromoType,
      };
    case SET_GRID_HOUR:
      return {
        ...state,
        gridHours: action.payload as HourAerodromoType,
      };
    case SET_VALIDADES:
      return {
        ...state,
        validades: action.payload as ValidadesAerodromoType,
      };
    default:
      return state;
  }
  return state;
};

export default aerodromoReducer;
