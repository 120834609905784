import { MenuActionTypes } from "./types";

const menuReducer = (state = "open", action: MenuActionTypes): string => {
  switch (action.type) {
    case "TOGGLE":
      return action.payload;

    default:
      return state;
  }
};

export default menuReducer;
