import React, { useCallback, useEffect, useState } from "react";
import {
  MapContainer,
  WMSTileLayer,
  ZoomControl,
  AttributionControl,
} from "react-leaflet";
import { CRS, LatLngTuple } from "leaflet";
import { VERSAO } from "../../config";
import Position from "../../components/Leaflet/Position";

import HorizontalMenu from "../../components/Leaflet/HorizontalMenu";
import aerodromosRepositories, { IStatus } from "../../repositories/Aeroportos";
import { Aerodromos } from "./components/Aerodromos";
import mensagensRepositories, {
  IAirmet,
  IAviso,
  ISigmet,
  ISpeci,
} from "../../repositories/Mensagens";
import { IPaginator, IResponse } from "../../repositories/models";
import Sigmet from "./components/Sigmet";
import useLayerUpdated from "../../hooks/useLayerUpdated";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const paises =
  "argentina,bolivia,brasil,chile,colombia,ecuador,guyana,paraguay,peru,surinam,uruguay,venezuela";

function extrairMens(dados: any) {
  return dados.map((row) => row.mens);
}
const layer = "monitoramento";

const Monitoramento = () => {
  const center: LatLngTuple = [-15.7801, -55.0];
  const zoom = 3;
  const minZoom = 2;

  const [aerodromos, setAerodromos] = useState<IStatus>();
  const [sigmet, setSigmet] = useState<ISigmet[]>([]);
  const [horizontalMenuItems, setHorizontalMenuItems] = useState([]);

  const layerRedux = useSelector(
    (state: RootState) => state.layers.data[layer as any]
  );

  const getData = useCallback(async () => {
    const itens: any = [];
    try {
      const [
        aerodromos,
        sigmetResponse,
        airmetResponse,
        speciResponse,
        avisoResponse,
      ] = await Promise.all([
        aerodromosRepositories.status<IStatus>(paises),
        mensagensRepositories.sigmet<IResponse<IPaginator<ISigmet>>>(),
        mensagensRepositories.airmet<IResponse<IPaginator<IAirmet>>>(),
        mensagensRepositories.speci<IResponse<IPaginator<ISpeci>>>(),
        mensagensRepositories.avisoPais<IResponse<IPaginator<IAviso>>>(),
      ]);

      const sigmetData = sigmetResponse.data.data.data;
      const airmetData = airmetResponse.data.data.data;
      const speciData = speciResponse.data.data.data;
      const avisoData = avisoResponse.data.data.data;

      setAerodromos(aerodromos.data);
      setSigmet(sigmetData);

      itens.push({
        title: "Sigmet",
        description: extrairMens(sigmetData),
        item: `Sigmet: ${sigmetData.length}`,
      });
      itens.push({
        title: "Airmet",
        description: extrairMens(airmetData),
        item: `Airmet: ${airmetData.length}`,
      });
      itens.push({
        title: "Speci",
        description: extrairMens(speciData),
        item: `Speci: ${speciData.length}`,
      });
      itens.push({
        title: "WO",
        description: extrairMens(avisoData),
        item: `WO: ${avisoData.length}`,
      });

      setHorizontalMenuItems(itens);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useLayerUpdated({
    onLoad: getData,
    seconds: 60,
    checkedLayer: true,
    layer,
  });

  useEffect(() => {
    getData();
  }, [getData]);

  // const horizontalMenuItems = [
  //   { item: "AirMET: 34" },
  //   { item: "SIGMET: 12" },
  //   { item: "SPECI: 10" },
  //   { item: "WO: 6" },
  //   { itemClass: "horizontal-menu-item-spacer" },
  //   {
  //     item: (
  //       <div style={{ textAlign: "center" }}>
  //         <b>Próxima Atualização</b>:
  //         <br />
  //         00:00
  //       </div>
  //     ),
  //     itemClass: "horizontal-menu-item-static",
  //   },
  //   {
  //     item: (
  //       <div style={{ textAlign: "center" }}>
  //         <b>Última Atualização</b>:
  //         <br />
  //         2022/05/30 13:59:25
  //       </div>
  //     ),
  //     itemClass: "horizontal-menu-item-static",
  //   },
  // ];

  return (
    <HorizontalMenu
      items={horizontalMenuItems}
      time={layerRedux?.time}
      lastUpdate={layerRedux?.lastUpdate}
    >
      <MapContainer
        center={center}
        zoom={zoom}
        minZoom={minZoom}
        zoomControl={false}
        attributionControl={false}
        crs={CRS.EPSG4326}
      >
        <AttributionControl position="topright" prefix={`REDEMET ${VERSAO}`} />
        <ZoomControl position="bottomleft" />

        <WMSTileLayer
          layers="OSM-WMS"
          url="https://ows.terrestris.de/osm/service?"
        />

        <Position />
        {aerodromos && <Aerodromos data={aerodromos} />}
        {sigmet && <Sigmet data={sigmet} />}
      </MapContainer>
    </HorizontalMenu>
  );
};

export default Monitoramento;
