import React, { FunctionComponent, ReactNode } from "react";
import { ReactComponent as ChevronUp } from "../../../assets/svg/icons/icon-chevron-up.svg";
import "./styles.css";
import { menuToggle } from "../../../store/Menu/actionCreatores";
import { useDispatch } from "react-redux";

type props = {
  children: React.ReactNode;
  show: boolean;
  logoSlot?: ReactNode;
  toggleSidebar?: (v: boolean) => void;
};

const logoStyles = {
  backgroundColor: "#fff",
  width: "100%",
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const MainSidebar: FunctionComponent<props> = ({
  children,
  show,
  logoSlot,
}: props) => {
  const dispatch = useDispatch();
  return (
    <div
      className={show ? "redemet-mainsidebar is-active" : "redemet-mainsidebar"}
    >
      {logoSlot && (
        <div className="redemet-mainsidebar-logo">
          <div className="logo" style={logoStyles}>
            {logoSlot}
          </div>
          <button
            className="toggle-sidebar"
            onClick={() => dispatch(menuToggle("close"))}
          >
            <ChevronUp />
          </button>
        </div>
      )}
      <div className="redemet-mainsidebar-scroller">
        <div className="redemet-mainsidebar-inner">{children}</div>
      </div>
    </div>
  );
};

export default MainSidebar;
