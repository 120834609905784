import React from "react";
import "./style.css";
import { Box } from "@material-ui/core";

const LegendRadar = () => {
  return (
    <Box style={{ padding: "5px" }}>
      <table width="100%" className="radar">
        <tbody>
          <tr style={{ height: "5px" }}>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr style={{ height: "5px", fontSize: "10px", textAlign: "center" }}>
            <td colSpan={17}>
              <b>Refletividade (dBZ)</b>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>M.FRACO</td>
            <td colSpan={2}>FRACO</td>
            <td colSpan={3}>MODERADO</td>
            <td colSpan={6}>FORTE</td>
            <td colSpan={2}>M.FORTE</td>
          </tr>
          <tr style={{ height: "5px" }}>
            <td colSpan={4} className="radarBordaTop"></td>
            <td colSpan={2} className="radarBordaTop"></td>
            <td colSpan={3} className="radarBordaTop"></td>
            <td colSpan={6} className="radarBordaTop"></td>
            <td colSpan={2} className="radarBordaTop"></td>
          </tr>
          <tr style={{ height: "5px", border: "1" }}>
            <td colSpan={3} style={{ textAlign: "left" }}>
              0
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}>
              2
            </td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              0
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}>
              3
            </td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              0
            </td>
            <td colSpan={2} style={{ textAlign: "right" }}>
              4
            </td>
            <td colSpan={5} style={{ textAlign: "left" }}>
              5
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}>
              6
            </td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              3
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}>
              75
            </td>
          </tr>
          <tr>
            <td
              colSpan={4}
              style={{
                background:
                  "linear-gradient(to right, rgb(117, 255, 255), rgb(0, 0, 246))",
              }}
            >
              &nbsp;
            </td>
            <td
              colSpan={3}
              style={{
                background:
                  "linear-gradient(to right, rgb(0, 255, 0), rgb(0, 153, 0))",
              }}
            >
              &nbsp;
            </td>
            <td
              colSpan={3}
              style={{
                background:
                  "linear-gradient(to right, rgb(255, 255, 0), rgb(255, 153, 0))",
              }}
            >
              &nbsp;
            </td>
            <td
              colSpan={4}
              style={{
                background:
                  "linear-gradient(to right, rgb(255, 0, 0), rgb(196, 0, 1))",
              }}
            >
              &nbsp;
            </td>
            <td
              colSpan={3}
              style={{
                background:
                  "linear-gradient(to right, rgb(255, 0, 255), rgb(151, 88, 201))",
              }}
            >
              &nbsp;
            </td>
          </tr>
          <tr style={{ height: "5px", border: "1" }}>
            <td colSpan={3} style={{ textAlign: "left" }}>
              0
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}></td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              1
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}></td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              3
            </td>
            <td colSpan={2} style={{ textAlign: "right" }}>
              2
            </td>
            <td colSpan={5} style={{ textAlign: "left" }}>
              5
            </td>
            <td colSpan={1} style={{ textAlign: "left" }}></td>
            <td colSpan={1} style={{ textAlign: "left" }}>
              &gt;100
            </td>
            <td colSpan={1} style={{ textAlign: "right" }}></td>
          </tr>
          <tr style={{ height: "5px" }}>
            <td colSpan={4} className="radarBordaInf"></td>
            <td colSpan={2} className="radarBordaInf"></td>
            <td colSpan={3} className="radarBordaInf"></td>
            <td colSpan={6} className="radarBordaInf"></td>
            <td colSpan={2} className="radarBordaInf"></td>
          </tr>
          <tr style={{ height: "5px", fontSize: "10px", textAlign: "center" }}>
            <td colSpan={17}>
              <b>Potencial de Precipitação (mm/h)</b>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default LegendRadar;
