import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Bar, defaults } from "react-chartjs-2";
import { ILine, mountDataset, mountOptions } from "./functionsCharts";
import { ChartDataSets } from "chart.js";

const BarChart = (props: ILine) => {
  const theme = useSelector((state: RootState) => state.theme);
  const options = mountOptions(props);

  const inputEl: any = useRef(null);
  const [legend, setLegend] = useState("");

  useEffect(() => {
    setLegend(inputEl.current.chartInstance.generateLegend());
  }, [inputEl]);

  // useEffect(() => {
  //     Chart.plugins.register({
  //         id: 'uteitei',
  //         beforeDraw: function(chartInstance) {
  //             const ctx = chartInstance.ctx;
  //             const chartArea = chartInstance.chartArea;
  //             let rowCount:any = chartInstance.data.labels?.length;
  //             rowCount -= 1;
  //             const width:any = chartArea.right - chartArea.left;
  //             const height:any = chartArea.bottom - chartArea.top;
  //             const rowWidth:any = width / rowCount;
  //             let startPoint:any = chartArea.left;
  //             let loop = 0;
  //             const hPassadas:any = props.horasPassadas;
  //             if(ctx?.createLinearGradient){
  //                 const grd = ctx.createLinearGradient(0, 0, (rowWidth*hPassadas), 0);
  //                 grd.addColorStop(0, "white");
  //                 grd.addColorStop(1, 'rgba(128, 128, 128,1)');
  //
  //                 while (startPoint < chartArea.right) {
  //                     if(loop < hPassadas){
  //                         ctx.fillStyle = grd;
  //                         ctx.fillRect(startPoint, chartArea.top, rowWidth, height);
  //                     }else{
  //                         ctx.fillStyle = 'rgba(255, 255, 255, 1)';
  //                         ctx.fillRect(startPoint, chartArea.top, rowWidth, height);
  //                     }
  //                     startPoint += rowWidth;
  //                     loop += 1;
  //                 }
  //             }
  //         }
  //     });
  // },[props.horasPassadas]);

  defaults.global.defaultColor = theme.gridColor;
  defaults.global.defaultFontColor = theme.fontColor;
  defaults.global.defaultFontFamily = "Arial";

  const data: ChartDataSets[] = props.dados.map((row) => {
    return mountDataset(
      row.label,
      (theme.linesColor as any)[row.color],
      row.data,
      row.id
    );
  });

  return (
    <React.Fragment>
      <Bar
        ref={inputEl}
        data={{
          labels: props.labels,
          datasets: data,
        }}
        options={options}
      />
      <div dangerouslySetInnerHTML={{ __html: legend }} />
    </React.Fragment>
  );
};

export default BarChart;
