import React, { FunctionComponent } from "react";
import { Slider } from "@material-ui/core";
import "./styles.css";

type props = {
  opacityValue: number | number[];
  maxValue?: number;
  step: number;
  minValue: number;
  onOpacityChange: any;
};

const OpacityControl: FunctionComponent<props> = ({
  opacityValue,
  maxValue,
  step,
  minValue,
  onOpacityChange,
}: props) => (
  <div
    className="layer-control-item opacity-control"
    style={{ marginLeft: "5px" }}
  >
    <label>Opacidade:</label>
    <Slider
      value={opacityValue}
      onChange={(event, number) => onOpacityChange(event, number)}
      step={step}
      min={minValue}
      max={maxValue}
    />
  </div>
);

export default OpacityControl;
