import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  totalImagens: number;
  tempo: any[];
}

export default function useControlAnimation(props: Props) {
  const { totalImagens } = props;
  const [index, setIndex] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(1);
  const [velocidade, setVelocidade] = useState<number>(500);
  const [running, setRunning] = useState<boolean>(false);
  const [tempoIndex, setTempoIndex] = useState<string>("");

  const indexRef: any = useRef();

  const play = useCallback(() => {
    if (!indexRef.current) {
      setRunning(true);
      indexRef.current = setInterval(
        () =>
          setIndex((prevState) =>
            prevState == props.totalImagens - 1 ? 0 : prevState + 1
          ),
        velocidade
      );
    }
  }, [indexRef, velocidade, props.totalImagens]);

  const clearIntervalRef = useCallback(() => {
    if (indexRef.current) {
      setRunning(false);
      clearInterval(indexRef.current);
      indexRef.current = undefined;
    }
  }, [indexRef]);

  const pause = useCallback(() => {
    setRunning(false);
    clearIntervalRef();
  }, [clearIntervalRef]);

  useEffect(() => {
    if (!props.tempo || props.tempo[index] !== undefined) {
      setTempoIndex(props.tempo[index]);
    }
  }, [index, props.tempo]);

  useEffect(() => {
    if (running) {
      pause();
      play();
    }
  }, [velocidade, pause, play, running]);

  useEffect(() => {
    if (totalImagens > 0) {
      setIndex(totalImagens - 1);
    }
  }, [totalImagens]);

  const changeIndex = useCallback(
    (index: number | number[]) => {
      clearIntervalRef();
      setIndex(index as number);
    },
    [clearIntervalRef]
  );

  const changeOpacity = useCallback((value: number | number[]) => {
    setOpacity(value as number);
  }, []);

  const changeVelocidade = useCallback((value: number | number[]) => {
    setVelocidade(value as number);
  }, []);

  return {
    index,
    opacity,
    velocidade,
    running,
    tempoIndex,
    play,
    pause,
    changeIndex,
    changeOpacity,
    changeVelocidade,
  };
}
