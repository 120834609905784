import React, { useState, FunctionComponent } from "react";
import "./styles.css";

type props = {
  children: React.ReactNode;
  onClick?: (v: boolean) => void;
  href?: string;
  target?: string;
};

const SidebarMenuItem: FunctionComponent<props> = ({
  children,
  onClick,
  href,
  target = "_self",
}: props) => {
  const [activeState, setActive] = useState<boolean>(false);

  function linkWrapper(child: any) {
    if (href)
      return (
        <a className="item-wrapper" href={href} target={target}>
          {child}
        </a>
      );

    return <span className="item-wrapper">{child}</span>;
  }

  return (
    <li
      className={
        activeState
          ? "redemet-mainsidebar-menu-item is-active"
          : "redemet-mainsidebar-menu-item"
      }
      onClick={() => {
        if (onClick) setActive(!activeState);
        if (onClick) onClick(activeState);
      }}
    >
      {linkWrapper(children)}
    </li>
  );
};

export default SidebarMenuItem;
